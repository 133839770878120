import React, { useState, useEffect } from 'react';
import AuthComponent from './components/auth/AuthComponent';
import DashboardComponent from './components/DashboardComponent';
import { getAuthToken, setAuthToken, logout } from './components/apiService';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const token = getAuthToken();
      if (token) {
        setAuthToken(token);
        setIsAuthenticated(true);
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  const handleLoginSuccess = (userData) => {
    setIsAuthenticated(true);
    setUser(userData.user);
  };

  const handleLogout = () => {
    logout();
    setIsAuthenticated(false);
    setUser(null);
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="app">
      {!isAuthenticated ? (
        <AuthComponent onLoginSuccess={handleLoginSuccess} />
      ) : (
        <DashboardComponent onLogout={handleLogout} user={user} />
      )}
    </div>
  );
};

export default App;
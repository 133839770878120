import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { FaStickyNote, FaUserEdit, FaSyncAlt, FaHistory, FaPhoneSlash } from 'react-icons/fa';

const Panel = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FixedHeader = styled.div`
  padding: 24px 24px 0;
  background-color: #fff;
  border-bottom: 1px solid #dadce0;
`;

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 24px;
`;

const FixedActions = styled.div`
  padding: 24px;
  background-color: #fff;
  border-top: 1px solid #dadce0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Avatar = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #1a73e8;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-right: 16px;
`;

const Name = styled.h2`
  margin: 0;
  font-size: 22px;
  font-weight: 400;
  color: #202124;
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: #5f6368;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
`;

const InfoItem = styled.div`
  background-color: #f1f3f4;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  word-wrap: break-word;
  overflow-wrap: break-word;

  &:hover {
    background-color: #e8f0fe;
  }
`;

const InfoLabel = styled.div`
  font-size: 12px;
  color: #5f6368;
  margin-bottom: 4px;
`;

const InfoValue = styled.div`
  font-size: 14px;
  color: #202124;
  font-weight: 500;
  white-space: pre-wrap;
`;

const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
  background-color: #fff;
  border-color: #dadce0;
  color: #1a73e8;
  font-weight: 500;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #174ea6;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(26,115,232,0.4);
  }

  svg {
    margin-right: 8px;
  }
`;

const InfoItemWithFallback = ({ label, value }) => (
  <InfoItem>
    <InfoLabel>{label}</InfoLabel>
    <InfoValue isEmpty={!value}>
      {value || 'Sin Datos'}
    </InfoValue>
  </InfoItem>
);

export const TaxesSlidingPanel = ({ 
  selectedRow, 
  openNotesModal, 
  handleOpenStatusModal, 
  openContactModal, 
  openHistoryModal,
  openContactStatusModal,
  onClose
}) => {

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  if (!selectedRow) return null;

  const getInitials = (name) => {
    return name.split(' ').map(n => n[0]).join('').toUpperCase();
  };

  return (
    <Panel>
      <FixedHeader>
        <Header>
          <Avatar>{getInitials(`${selectedRow.contact_info.first_name} ${selectedRow.contact_info.last_name}`)}</Avatar>
          <Name>{selectedRow.first_name} {selectedRow.last_name}</Name>
        </Header>
        <SectionTitle>Personal Information</SectionTitle>
      </FixedHeader>

      <ScrollableContent>
        <Section>
          <InfoGrid>
            <InfoItemWithFallback label="Email" value={selectedRow.contact_info.email} />
            <InfoItemWithFallback label="Phone" value={selectedRow.contact_info.phone_number} />
            <InfoItemWithFallback label="Address" value={selectedRow.address.address} />
            <InfoItemWithFallback label="Postal Code" value={selectedRow.address.postal_code} />
          </InfoGrid>
        </Section>

        <Section>
          <SectionTitle>Insurance Details</SectionTitle>
          <InfoGrid>
            <InfoItemWithFallback label="Status" value={selectedRow.status.name} />
            <InfoItemWithFallback 
              label="Created At" 
              value={selectedRow.created_at ? new Date(selectedRow.created_at).toLocaleString() : null} 
            />
          </InfoGrid>
        </Section>

        <Section>
          <SectionTitle>Contact Details</SectionTitle>
          <InfoGrid>
            <InfoItemWithFallback label="Days Available" value={selectedRow.contact_info.contact_days ? selectedRow.contact_info.contact_days.replace(/,/g, '\n') : 'Sin Datos'} />
            <InfoItemWithFallback label="Hours Available" value={selectedRow.contact_info.contact_time} />
            <InfoItemWithFallback 
              label="Call Attempt" 
              value={selectedRow.contact_history ? selectedRow.contact_history.length.toString() : '0'} 
            />
          </InfoGrid>
        </Section>
      </ScrollableContent>

      <FixedActions>
        <Section>
          <SectionTitle>Contact Actions</SectionTitle>
          <ActionButton onClick={() => openContactStatusModal(selectedRow)}>
            <FaPhoneSlash /> Call Status
          </ActionButton>
        </Section>

        <Section>
          <SectionTitle>Actions</SectionTitle>
          <ActionButton onClick={() => openNotesModal(selectedRow)}>
            <FaStickyNote /> Notes
          </ActionButton>
          <ActionButton onClick={() => handleOpenStatusModal(selectedRow)}>
            <FaSyncAlt /> Change Status
          </ActionButton>
          <ActionButton onClick={() => openContactModal(selectedRow)}>
            <FaUserEdit /> Update Contact
          </ActionButton>
          <ActionButton onClick={() => openHistoryModal(selectedRow)}>
            <FaHistory /> History
          </ActionButton>
        </Section>
      </FixedActions>
    </Panel>
  );
};
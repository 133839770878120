import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { CookBookModal } from './CookBookModals'; 

const GoogleButton = styled(Button)`
  border-radius: 4px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 24px;

  &.btn-primary {
    background-color: #1a73e8;
    border-color: #1a73e8;
    color: #fff;
    
    &:hover, &:focus {
      background-color: #1765cc;
      border-color: #1765cc;
    }

    &:disabled {
      background-color: #8ab4f8;
      border-color: #8ab4f8;
    }
  }

  &.btn-secondary {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
    }
  }
`;

const Panel = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15);
  padding: 24px;
  height: 100%;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Avatar = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #1a73e8;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-right: 16px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 22px;
  font-weight: 400;
  color: #202124;
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: #5f6368;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
`;

const InfoItem = styled.div`
  background-color: #f1f3f4;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  word-wrap: break-word;
  overflow-wrap: break-word;

  &:hover {
    background-color: #e8f0fe;
  }
`;

const InfoLabel = styled.div`
  font-size: 12px;
  color: #5f6368;
  margin-bottom: 4px;
`;

const InfoValue = styled.div`
  font-size: 14px;
  color: #202124;
  font-weight: 500;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 16px;
`;

const Image = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
`;

const UpdateButton = styled(GoogleButton)`
  margin-top: 24px;
`;

export const CookBookSlidingPanel = ({ selectedCookBook, onClose, onUpdate }) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  if (!selectedCookBook) return null;

  const handleUpdate = async (updatedCookBook, images) => {
    try {
      onUpdate(updatedCookBook, images);
    setShowUpdateModal(false);
    } catch (error) {
      console.error('Error updating cookbook:', error);
      // Mostrar un mensaje de error al usuario
    }
  };

  return (
    <Panel>
      <Header>
        <Avatar>{selectedCookBook.title[0].toUpperCase()}</Avatar>
        <Title>{selectedCookBook.title}</Title>
      </Header>

      <Section>
        <SectionTitle>Cookbook Information</SectionTitle>
        <InfoGrid>
          <InfoItem>
            <InfoLabel>Description</InfoLabel>
            <InfoValue>{selectedCookBook.description}</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>Preparation Time</InfoLabel>
            <InfoValue>{selectedCookBook.preparation_time} minutes</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>Servings</InfoLabel>
            <InfoValue>{selectedCookBook.servings}</InfoValue>
          </InfoItem>
        </InfoGrid>
      </Section>

      <Section>
        <SectionTitle>Ingredients ({selectedCookBook.ingredients.length})</SectionTitle>
        <InfoGrid>
          {selectedCookBook.ingredients.map((ingredient, index) => (
            <InfoItem key={index}>
              <InfoValue>
                {ingredient.quantity} {ingredient.unit} {ingredient.name}
              </InfoValue>
            </InfoItem>
          ))}
        </InfoGrid>
      </Section>

      <Section>
        <SectionTitle>Steps ({selectedCookBook.steps.length})</SectionTitle>
        {selectedCookBook.steps.map((step, index) => (
          <InfoItem key={index}>
            <InfoLabel>Step {step.order}</InfoLabel>
            <InfoValue>{step.description}</InfoValue>
            {step.image && <Image src={step.image} alt={`Step ${step.order}`} />}
          </InfoItem>
        ))}
      </Section>

      <Section>
        <SectionTitle>Images</SectionTitle>
        <ImageGrid>
          {selectedCookBook.pictures.map((pic, index) => (
            <Image key={index} src={pic} alt={`Cookbook image ${index + 1}`} />
          ))}
        </ImageGrid>
      </Section>

      <UpdateButton variant="primary" onClick={() => setShowUpdateModal(true)}>
        Update Cookbook
      </UpdateButton>

      <CookBookModal
        show={showUpdateModal}
        onHide={() => setShowUpdateModal(false)}
        cookBook={selectedCookBook}
        onSubmit={handleUpdate}
        isUpdating={true}
      />
    </Panel>
  );
};
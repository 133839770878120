import api from '../apiBase';

export const fetchTaxes = async (params) => {
  try {
    const response = await api.get('/api/taxes/v1/agent', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching taxes:', error);
    throw error;
  }
};

export const fetchTaxesAll = async (params) => {
  try {
    const response = await api.get('/api/taxes/v1', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching all taxes:', error);
    throw error;
  }
};

export const addNote = async (taxId, text) => {
  try {
    await api.post(`/api/taxes/v1/${taxId}/notes`, { text });
  } catch (error) {
    console.error('Error adding note:', error);
    throw error;
  }
};

export const editNote = async (taxId, noteIndex, text) => {
  try {
    await api.put(`/api/taxes/v1/${taxId}/notes/${noteIndex}`, { text });
  } catch (error) {
    console.error('Error editing note:', error);
    throw error;
  }
};

export const deleteNote = async (taxId, noteIndex) => {
  try {
    await api.delete(`/api/taxes/v1/${taxId}/notes/${noteIndex}`);
  } catch (error) {
    console.error('Error deleting note:', error);
    throw error;
  }
};

export const changeStatus = async (taxId, newStatus, note) => {
  try {
    await api.put(`/api/taxes/v1/${taxId}/status`, newStatus, { params: { note } });
  } catch (error) {
    console.error('Error changing status:', error);
    throw error;
  }
};

export const updateContactInfo = async (taxId, contactInfo) => {
  try {
    await api.put(`/api/taxes/v1/${taxId}/contact`, contactInfo);
  } catch (error) {
    console.error('Error updating contact info:', error);
    throw error;
  }
};

export const getAvailableStatuses = async (taxId) => {
  try {
    const response = await api.get(`/api/taxes/v1/${taxId}/available-statuses`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching available statuses:', error);
    throw error;
  }
};

export const getStatuses = async () => {
  try {
    const response = await api.get(`/api/taxes/v1/statuses`);
    return response.data;
  } catch (error) {
    console.error('Error fetching available statuses:', error);
    throw error;
  }
};

export const fetchAgents = async () => {
  try {
    const response = await api.get('/api/taxes/v1/agents');
    return response.data;
  } catch (error) {
    console.error('Error fetching agents:', error);
    throw error;
  }
};

export const assignAgent = async (taxIds, agentId, clientNotify) => {
  try {
    const params = new URLSearchParams();
    taxIds.forEach(id => params.append('ids', id));
    params.append('agentId', agentId);
    params.append('clientNotify', clientNotify);

    const response = await api.put('/api/taxes/v1/agent/assign', null, { params });

    if (response.data.error) {
      throw new Error(response.data.error);
    }

    return response.data;
  } catch (error) {
    console.error('Error assigning agent:', error);
    throw error;
  }
};

export const addContactStatus = async (taxId, newContactStatus) => {
  try {
    const response = await api.put(`/api/taxes/v1/${taxId}/contactStatus`, newContactStatus);
    return response.data;
  } catch (error) {
    console.error('Error adding contact status:', error);
    throw error;
  }
};

export const fetchTaxesAgents = async () => {
  try {
    const response = await api.get('/api/workers/by-role-taxes-agent');
    return response.data;
  } catch (error) {
    console.error('Error fetching notary agents:', error);
    throw error;
  }
};

export default api;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { FaEdit, FaImages, FaUserCircle, FaPencilAlt, FaClipboardList } from 'react-icons/fa';
import { TypeModal, ProfileImageModal, ImagesModal, CreateEditModal, IncidentActivitiesModal } from './incidentPeopleModals';
import { getIncidentPeopleTypes } from './apiIncidentPeople';

const Panel = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FixedHeader = styled.div`
  padding: 24px 24px 0;
  background-color: #fff;
  border-bottom: 1px solid #dadce0;
`;

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 24px;
`;

const FixedActions = styled.div`
  padding: 24px;
  background-color: #fff;
  border-top: 1px solid #dadce0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Name = styled.h2`
  margin: 0;
  font-size: 22px;
  font-weight: 400;
  color: #202124;
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: #5f6368;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
`;

const InfoItem = styled.div`
  background-color: #f1f3f4;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  word-wrap: break-word;
  overflow-wrap: break-word;

  &:hover {
    background-color: #e8f0fe;
  }
`;

const InfoLabel = styled.div`
  font-size: 12px;
  color: #5f6368;
  margin-bottom: 4px;
`;

const InfoValue = styled.div`
  font-size: 14px;
  color: #202124;
  font-weight: 500;
  white-space: pre-wrap;
`;

const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
  background-color: #fff;
  border-color: #dadce0;
  color: #1a73e8;
  font-weight: 500;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #174ea6;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  svg {
    margin-right: 8px;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
  margin-top: 12px;
`;

const Image = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
`;

const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 16px;
`;

const InfoItemWithFallback = ({ label, value }) => (
  <InfoItem>
    <InfoLabel>{label}</InfoLabel>
    <InfoValue>{value || 'No data'}</InfoValue>
  </InfoItem>
);

export const IncidentPeopleSlidingPanel = ({ 
  selectedPerson,
  onClose,
  onUpdate
}) => {
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [showProfileImageModal, setShowProfileImageModal] = useState(false);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [showActivitiesModal, setShowActivitiesModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [types, setTypes] = useState([]);
  const [activityCount, setActivityCount] = useState(0);

  useEffect(() => {
    const loadTypes = async () => {
      try {
        const typesData = await getIncidentPeopleTypes();
        setTypes(typesData);
      } catch (error) {
        console.error('Error loading types:', error);
      }
    };

    loadTypes();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  const handleSuccess = () => {
    onUpdate();
  };

  const handleActivityUpdate = (count) => {
    setActivityCount(count);
  };

  if (!selectedPerson) return null;

  return (
    <Panel>
      <FixedHeader>
        <Header>
          <Name>{selectedPerson.name}</Name>
        </Header>
        {selectedPerson.profileImage && (
          <ProfileImage src={selectedPerson.profileImage} alt="Profile" />
        )}
      </FixedHeader>

      <ScrollableContent>
        <Section>
          <SectionTitle>Basic Information</SectionTitle>
          <InfoGrid>
            <InfoItemWithFallback label="Type" value={selectedPerson.type} />
            <InfoItemWithFallback label="Province" value={selectedPerson.province} />
            <InfoItemWithFallback label="Municipality" value={selectedPerson.municipality} />
            <InfoItemWithFallback 
              label="Date" 
              value={new Date(selectedPerson.date).toLocaleDateString()} 
            />
          </InfoGrid>
        </Section>

        <Section>
          <SectionTitle>Images</SectionTitle>
          <ImageGrid>
            {selectedPerson.images.map((image, index) => (
              <Image key={index} src={image} alt={`Person image ${index + 1}`} />
            ))}
          </ImageGrid>
        </Section>
      </ScrollableContent>

      <FixedActions>
        <Section>
          <SectionTitle>Actions</SectionTitle>
          <ActionButton onClick={() => setShowEditModal(true)}>
            <FaPencilAlt /> Edit Person
          </ActionButton>
          <ActionButton onClick={() => setShowActivitiesModal(true)}>
            <FaPencilAlt /> Activities
          </ActionButton>
          <ActionButton onClick={() => setShowTypeModal(true)}>
            <FaEdit /> Change Type
          </ActionButton>
          <ActionButton onClick={() => setShowProfileImageModal(true)}>
            <FaUserCircle /> Update Profile Image
          </ActionButton>
          <ActionButton onClick={() => setShowImagesModal(true)}>
            <FaImages /> Manage Images
          </ActionButton>
        </Section>
      </FixedActions>

      <CreateEditModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        person={selectedPerson}
        onSuccess={handleSuccess}
        types={types}
      />

      <TypeModal
        show={showTypeModal}
        onHide={() => setShowTypeModal(false)}
        person={selectedPerson}
        onSuccess={handleSuccess}
        types={types}
      />

      <ProfileImageModal
        show={showProfileImageModal}
        onHide={() => setShowProfileImageModal(false)}
        person={selectedPerson}
        onSuccess={handleSuccess}
      />

      <ImagesModal
        show={showImagesModal}
        onHide={() => setShowImagesModal(false)}
        person={selectedPerson}
        onSuccess={handleSuccess}
      />

      <IncidentActivitiesModal
        show={showActivitiesModal}
        onHide={() => setShowActivitiesModal(false)}
        selectedPerson={selectedPerson}
        onActivityCountUpdate={handleActivityUpdate}
      />
    </Panel>
  );
};
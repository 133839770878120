import api from '../apiBase';

export const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem('authToken', token);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    clearAuthToken();
  }
};

export const clearAuthToken = () => {
  localStorage.removeItem('authToken');
  delete api.defaults.headers.common['Authorization'];
};

export const login = async (email, password) => {
  try {
    const response = await api.post('/auth/v1/emailLogin', null, {
      params: { email, password },
    });
    if (response.data && response.data.firebase_auth_data) {
      setAuthToken(response.data.firebase_auth_data.id_token);
    }
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const logout = () => {
  clearAuthToken();
};

export const invalidateAccount = async (userId) => {
  try {
    const response = await api.post('/auth/v1/invalidateAccount', null, {
      params: { uid: userId }
    });
    return response.data;
  } catch (error) {
    console.error('Error invalidating account:', error);
    throw error;
  }
};
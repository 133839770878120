import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Modal, Form, ListGroup, Button, InputGroup } from 'react-bootstrap';
import { FaEdit, FaTrash, FaCircle, FaRegCircle, FaToggleOn, FaToggleOff } from 'react-icons/fa';

const GoogleModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.3), 0 4px 8px 3px rgba(60,64,67,0.15);
  }

  .modal-header {
    border-bottom: 1px solid #e8eaed;
    padding: 24px 24px 20px;
  }

  .modal-title {
    font-size: 22px;
    color: #202124;
    font-weight: 400;
  }

  .modal-body {
    padding: 24px;
  }

  .modal-footer {
    border-top: 1px solid #e8eaed;
    padding: 20px 24px 24px;
  }
`;

const GoogleForm = styled(Form)`
  .form-label {
    font-weight: 500;
    color: #5f6368;
    margin-bottom: 8px;
  }

  .form-control {
    border-radius: 4px;
    border: 1px solid #dadce0;
    padding: 8px 12px;
    font-size: 14px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
    }
  }

  .form-select {
    border-radius: 4px;
    border: 1px solid #dadce0;
    padding: 8px 12px;
    font-size: 14px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
    }
  }
`;

const GoogleListGroup = styled(ListGroup)`
  .list-group-item {
    border: none;
    background-color: #f1f3f4;
    margin-bottom: 8px;
    border-radius: 4px;
    padding: 12px;
  }
`;

const GoogleButton = styled(Button)`
  border-radius: 4px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 24px;

  &.btn-primary {
    background-color: #1a73e8;
    border-color: #1a73e8;
    color: #fff;
    
    &:hover, &:focus {
      background-color: #1765cc;
      border-color: #1765cc;
    }

    &:disabled {
      background-color: #8ab4f8;
      border-color: #8ab4f8;
    }
  }

  &.btn-secondary {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
    }
  }

  &.btn-danger {
    background-color: #ea4335;
    border-color: #ea4335;
    color: #fff;
    
    &:hover, &:focus {
      background-color: #d93025;
      border-color: #d93025;
    }
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    border-bottom: none;
    padding: 20px 20px 0;
  }

  .modal-body {
    padding: 20px;
  }

  .modal-footer {
    border-top: none;
    padding: 0 20px 20px;
  }
`;

const ActionButton = styled(GoogleButton)`
  padding: 4px 8px;
  font-size: 14px;
`;

const TimelineContainer = styled.div`
  padding: 20px;
  max-height: 60vh;
  overflow-y: auto;
`;

const TimelineItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    left: 11px;
    top: 24px;
    bottom: -24px;
    width: 2px;
    background: #dadce0;
  }

  &:last-child:after {
    display: none;
  }
`;

const TimelineIcon = styled.div`
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 3px;
  z-index: 1;
`;

const TimelineContent = styled.div`
  margin-left: 20px;
  padding: 10px;
  background-color: #f1f3f4;
  border-radius: 8px;
  flex-grow: 1;
`;

const TimelineDate = styled.p`
  font-size: 0.9em;
  color: #5f6368;
  margin-bottom: 5px;
`;

const TimelineTitle = styled.h5`
  font-size: 1em;
  color: #1a73e8;
  margin-bottom: 5px;
`;

const TimelineNote = styled.p`
  font-size: 0.9em;
  color: #3c4043;
  margin-bottom: 0;
`;

const ModalText = styled.p`
  color: #3c4043;
  font-size: 16px;
  margin-bottom: 0;
`;


// Ahora, actualizamos cada componente modal para usar estos nuevos estilos

export const NotesModal = ({ show, onHide, selectedInsurance, newNote, setNewNote, handleAddNote, openEditNoteModal, handleDeleteNote }) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(newNote.trim().length > 0);
  }, [newNote]);

  return (
    <GoogleModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Notes
          <small className="text-muted ms-2">({selectedInsurance?.notes?.length || 0} notes)</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleListGroup className="mb-3" style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {selectedInsurance?.notes?.map((note, index) => (
            <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
              <div>
                <p className="mb-0">{note.text}</p>
                <small className="text-muted">{new Date(note.date).toLocaleString()}</small>
              </div>
              <div>
                <ActionButton 
                  variant="secondary" 
                  className="me-2"
                  onClick={() => openEditNoteModal(index, note.text)}
                  title="Edit note"
                >
                  <FaEdit />
                </ActionButton>
                <ActionButton 
                  variant="danger" 
                  onClick={() => handleDeleteNote(index)}
                  title="Delete note"
                >
                  <FaTrash />
                </ActionButton>
              </div>
            </ListGroup.Item>
          ))}
        </GoogleListGroup>
        <GoogleForm>
          <Form.Group>
            <Form.Label>Add New Note</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              isInvalid={!isValid}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a note.
            </Form.Control.Feedback>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Close</GoogleButton>
        <GoogleButton variant="primary" onClick={handleAddNote} disabled={!isValid}>Add Note</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const EditNoteModal = ({ show, onHide, editingNote, setEditingNote, handleEditNote }) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(editingNote.text.trim().length > 0);
  }, [editingNote.text]);

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              value={editingNote.text}
              onChange={(e) => setEditingNote({ ...editingNote, text: e.target.value })}
              isInvalid={!isValid}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a note.
            </Form.Control.Feedback>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="primary" onClick={handleEditNote} disabled={!isValid}>Save Changes</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

// ... (Previous code remains the same)

export const StatusModal = ({ show, onHide, newStatus, setNewStatus, statusNote, setStatusNote, availableStatuses, handleChangeStatus }) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(newStatus !== "" && statusNote.trim().length > 0);
  }, [newStatus, statusNote]);

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Change Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group className="mb-3">
            <Form.Label>New Status</Form.Label>
            <Form.Select
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              isInvalid={newStatus === ""}
            >
              <option value="">Select a status</option>
              {availableStatuses.map((status) => (
                <option key={status} value={status}>{status}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select a status.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Note</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={statusNote}
              onChange={(e) => setStatusNote(e.target.value)}
              isInvalid={statusNote.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a note.
            </Form.Control.Feedback>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Close</GoogleButton>
        <GoogleButton variant="primary" onClick={handleChangeStatus} disabled={!isValid}>Change Status</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const DocumentModal = ({ show, onHide, selectedInsurance, documentName, setDocumentName, newDocument, handleDeleteDocument, getRootProps, getInputProps, removeNewDocument, handleAddDocument }) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(documentName.trim().length > 0 && newDocument !== null);
  }, [documentName, newDocument]);

  return (
    <GoogleModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Documents</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleListGroup className="mb-3" style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {selectedInsurance?.documents.map((doc, index) => (
            <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
              <span>{doc.name}</span>
              <GoogleButton variant="danger" size="sm" onClick={() => handleDeleteDocument(index)}>Delete</GoogleButton>
            </ListGroup.Item>
          ))}
        </GoogleListGroup>
        <GoogleForm>
          <Form.Group className="mb-3">
            <Form.Label>Document Name</Form.Label>
            <Form.Control
              type="text"
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
              placeholder="Enter document name"
              isInvalid={documentName.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a document name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Upload File</Form.Label>
            {newDocument === null ? (
              <div {...getRootProps()} style={{
                border: '2px dashed #dadce0',
                borderRadius: '4px',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                backgroundColor: '#f8f9fa'
              }}>
                <input {...getInputProps()} />
                <p style={{ color: '#5f6368', margin: 0 }}>Click here to attach a file</p>
              </div>
            ) : (
              <div className="d-flex justify-content-between align-items-center mt-2 p-2" style={{ backgroundColor: '#e8f0fe', borderRadius: '4px' }}>
                <span style={{ color: '#1967d2' }}>{newDocument.name}</span>
                <GoogleButton variant="danger" size="sm" onClick={removeNewDocument}>Remove</GoogleButton>
              </div>
            )}
            {newDocument === null && (
              <div className="text-danger mt-2">Please upload a file.</div>
            )}
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Close</GoogleButton>
        <GoogleButton variant="primary" onClick={handleAddDocument} disabled={!isValid}>Add Document</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const ContactModal = ({ show, onHide, contactInfo, setContactInfo, handleUpdateContactInfo, formatDateToInput, formatDateToOutput }) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const { first_name, last_name, birth_date, address, email, phone_number } = contactInfo;
    setIsValid(
      first_name?.trim().length > 0 &&
      last_name?.trim().length > 0 &&
      birth_date?.trim().length > 0 &&
      address?.trim().length > 0 &&
      email?.trim().length > 0 &&
      phone_number?.trim().length > 0
    );
  }, [contactInfo]);

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Contact Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group className="mb-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              value={contactInfo.first_name || ''}
              onChange={(e) => setContactInfo({ ...contactInfo, first_name: e.target.value })}
              isInvalid={contactInfo.first_name?.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a first name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              value={contactInfo.last_name || ''}
              onChange={(e) => setContactInfo({ ...contactInfo, last_name: e.target.value })}
              isInvalid={contactInfo.last_name?.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a last name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Birth Date</Form.Label>
            <Form.Control
              type="date"
              value={formatDateToInput(contactInfo.birth_date)}
              onChange={(e) => setContactInfo({ 
                ...contactInfo, 
                birth_date: formatDateToOutput(e.target.value) 
              })}
              isInvalid={!contactInfo.birth_date}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a birth date.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              value={contactInfo.address || ''}
              onChange={(e) => setContactInfo({ ...contactInfo, address: e.target.value })}
              isInvalid={contactInfo.address?.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter an address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={contactInfo.email || ''}
              onChange={(e) => setContactInfo({ ...contactInfo, email: e.target.value })}
              isInvalid={contactInfo.email?.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter an email address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="tel"
              value={contactInfo.phone_number || ''}
              onChange={(e) => setContactInfo({ ...contactInfo, phone_number: e.target.value })}
              isInvalid={contactInfo.phone_number?.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a phone number.
            </Form.Control.Feedback>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Close</GoogleButton>
        <GoogleButton variant="primary" onClick={handleUpdateContactInfo} disabled={!isValid}>Update</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const HistoryModal = ({ show, onHide, selectedInsurance }) => {
  const combinedHistory = useMemo(() => {
    const stateEvents = selectedInsurance?.state_history?.map(change => ({
      ...change,
      type: 'Status Event',
      date: new Date(change.change_date),
      status: change.to_status
    })) || [];

    const contactEvents = selectedInsurance?.contact_history?.map(contact => ({
      ...contact,
      type: 'Contact Event',
      date: new Date(contact.change_date),
      status: contact.contact_status
    })) || [];

    return [...stateEvents, ...contactEvents].sort((a, b) => b.date - a.date);
  }, [selectedInsurance]);

  return (
    <GoogleModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Event History</Modal.Title>
      </Modal.Header>
      <TimelineContainer>
        {combinedHistory.map((event, index) => (
          <TimelineItem key={index}>
            <TimelineIcon>
              {event.type === 'Status Event' ? <FaCircle color="#1a73e8" /> : <FaRegCircle color="#1a73e8" />}
            </TimelineIcon>
            <TimelineContent>
              <TimelineDate>{event.date.toLocaleString()}</TimelineDate>
              <TimelineTitle>{event.type}: {event.status}</TimelineTitle>
              <TimelineNote>
              Nota: {event.note ? event.note : 'Sin nota'}
              </TimelineNote>
              {event.type === 'Status Event' && (
                <TimelineNote>
                  <strong>From:</strong> {event.from_status} <strong>To:</strong> {event.to_status}
                </TimelineNote>
              )}
            </TimelineContent>
          </TimelineItem>
        ))}
      </TimelineContainer>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Close</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const AssignAgentModal = ({ show, onHide, agents, selectedAgent, setSelectedAgent, handleAssignAgent, selectedRowsCount }) => {
  const [notifyClient, setNotifyClient] = useState(false);

  const onAssign = () => {
    handleAssignAgent(notifyClient);
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Agent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ color: '#5f6368' }}>Selected rows: <strong>{selectedRowsCount}</strong></p>
        <GoogleForm>
          <Form.Group>
            <Form.Label>Select Agent</Form.Label>
            <Form.Select
              value={selectedAgent}
              onChange={(e) => setSelectedAgent(e.target.value)}
            >
              <option value="">Choose an agent</option>
              {agents.map((agent) => (
                <option key={agent.id} value={agent.id}>
                  {agent.name} ({agent.email})
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Check 
              type="checkbox"
              id="notifyClientCheck"
              label="Notify client"
              checked={notifyClient}
              onChange={(e) => setNotifyClient(e.target.checked)}
            />
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>
          Cancel
        </GoogleButton>
        <GoogleButton 
          variant="primary" 
          onClick={onAssign} 
          disabled={!selectedAgent}
        >
          Assign
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const PriceModal = ({ show, onHide, newPrice, setNewPrice, handleChangePrice }) => {

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Price</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group>
            <Form.Label>New Price</Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="number"
                value={newPrice}
                onChange={(e) => setNewPrice(e.target.value)}
                placeholder="Enter new price"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid price greater than 0.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton className="btn-secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton className="btn-primary" onClick={handleChangePrice}>
          Save
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const NotaryStatusModal = ({ 
  show, 
  onHide, 
  newStatus, 
  setNewStatus, 
  availableStatuses, 
  handleChangeStatus 
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Change Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>New Status</Form.Label>
          <Form.Control
            as="select"
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
          >
            <option value="">Select a status</option>
            {availableStatuses.map((status) => (
              <option key={status} value={status}>{status}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button 
          variant="primary" 
          onClick={handleChangeStatus}
          disabled={!newStatus}
        >
          Change Status
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const NotifyCallModal = ({ show, onHide, selectedInsurance, handleNotifyCall }) => {
  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Call Notification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to send a call notification to {selectedInsurance?.first_name} {selectedInsurance?.last_name}?</p>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="primary" onClick={handleNotifyCall}>Send Notification</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const ContactStatusModal = ({ show, onHide, selectedInsurance, handleAddContactStatus }) => {
  const [note, setNote] = useState('');
  const [phoneStatus, setPhoneStatus] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(phoneStatus !== '');
  }, [phoneStatus]);

  const handleSubmit = () => {
    handleAddContactStatus({
      note,
      contact_status: phoneStatus
    });
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Call Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group className="mb-3">
            <Form.Label>Call Status</Form.Label>
            <Form.Select
              value={phoneStatus}
              onChange={(e) => setPhoneStatus(e.target.value)}
              isInvalid={!isValid}
            >
              <option value="">Select a status</option>
              <option value="NO_ANSWER">No Answer</option>
              <option value="PHONE_BUSY">Phone Busy</option>
              <option value="FORWARDED_TO_VOICEMAIL">Forwarded to Voicemail</option>
              <option value="NUMBER_OUT_OF_SERVICE">Number Out of Service</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select a call status.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Note</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="primary" onClick={handleSubmit} disabled={!isValid}>
          Update Status
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const AlertModal = ({ show, onHide, title, message, variant }) => {
  return (
    <StyledModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant={variant === 'success' ? 'primary' : 'secondary'} onClick={onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
};

export const CreateUserModal = ({ show, onHide, handleCreateUser }) => {
  const [userData, setUserData] = useState({ name: '', email: '', password: '' });
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const { name, email, password } = userData;
    setIsValid(name.trim() !== '' && email.trim() !== '' && password.trim() !== '');
  }, [userData]);

  const handleSubmit = () => {
    handleCreateUser(userData);
    setUserData({ name: '', email: '', password: '' });
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={userData.name}
              onChange={(e) => setUserData({ ...userData, name: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={userData.email}
              onChange={(e) => setUserData({ ...userData, email: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={userData.password}
              onChange={(e) => setUserData({ ...userData, password: e.target.value })}
            />
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="primary" onClick={handleSubmit} disabled={!isValid}>Create User</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const EditNameModal = ({ show, onHide, currentName = '', handleUpdateName }) => {
  const [name, setName] = useState(currentName);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(name && name.trim() !== '');
  }, [name]);

  useEffect(() => {
    setName(currentName || '');
  }, [currentName]);

  const handleSubmit = () => {
    if (isValid) {
      handleUpdateName(name);
    }
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Name</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={!isValid}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a name.
            </Form.Control.Feedback>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="primary" onClick={handleSubmit} disabled={!isValid}>Update Name</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const ManageRolesModal = ({ show, onHide, currentRoles = [], handleAddRole, handleRemoveRole }) => {
  const [newRole, setNewRole] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(newRole && newRole.trim() !== '');
  }, [newRole]);

  const handleSubmit = () => {
    if (isValid) {
      handleAddRole(newRole);
      setNewRole('');
    }
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Manage Roles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleListGroup className="mb-3">
          {currentRoles.map((role, index) => (
            <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
              {role}
              <GoogleButton variant="danger" size="sm" onClick={() => handleRemoveRole(role)}>Remove</GoogleButton>
            </ListGroup.Item>
          ))}
        </GoogleListGroup>
        <GoogleForm>
          <Form.Group>
            <Form.Label>Add New Role</Form.Label>
            <Form.Control
              type="text"
              value={newRole}
              onChange={(e) => setNewRole(e.target.value)}
              isInvalid={!isValid && newRole !== ''}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a role.
            </Form.Control.Feedback>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Close</GoogleButton>
        <GoogleButton variant="primary" onClick={handleSubmit} disabled={!isValid}>Add Role</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const ContactInfoModal = ({ show, onHide, currentContactInfo, handleUpdateContactInfo }) => {
  const [contactInfo, setContactInfo] = useState(currentContactInfo || {});
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    // Actualizar el estado local solo cuando currentContactInfo cambie
    setContactInfo(currentContactInfo || {});
  }, [currentContactInfo]);

  useEffect(() => {
    // Validar los campos del formulario
    const { name, email, phones } = contactInfo;
    setIsValid(
      name?.trim() !== '' &&
      email?.trim() !== '' &&
      phones?.trim() !== ''
    );
  }, [contactInfo]);

  const handleSubmit = () => {
    if (isValid) {
      handleUpdateContactInfo(contactInfo);
      onHide();
    }
  };

  const handleInputChange = (field, value) => {
    setContactInfo(prev => ({ ...prev, [field]: value }));
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Contact Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={contactInfo.name || ''}
              onChange={(e) => handleInputChange('name', e.target.value)}
              isInvalid={!contactInfo.name}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={contactInfo.email || ''}
              onChange={(e) => handleInputChange('email', e.target.value)}
              isInvalid={!contactInfo.email}
            />
            <Form.Control.Feedback type="invalid">
              Please enter an email.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone Numbers (comma-separated)</Form.Label>
            <Form.Control
              type="text"
              value={contactInfo.phones || ''}
              onChange={(e) => handleInputChange('phones', e.target.value)}
              isInvalid={!contactInfo.phones}
            />
            <Form.Control.Feedback type="invalid">
              Please enter at least one phone number.
            </Form.Control.Feedback>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="primary" onClick={handleSubmit} disabled={!isValid}>Update Contact Info</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const InvalidateAccountModal = ({ show, onHide, onConfirm, userName }) => {
  return (
    <GoogleModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Invalidate Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to invalidate the account for <strong>{userName}</strong>?</p>
        <p>This action cannot be undone.</p>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>
          Cancel
        </GoogleButton>
        <GoogleButton variant="danger" onClick={onConfirm}>
          Invalidate Account
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const EditSiteModal = ({ show, onHide, site, handleUpdateSite }) => {
  const [editedSite, setEditedSite] = useState(site);

  useEffect(() => {
    setEditedSite(site);
  }, [site]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedSite(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdateSite(editedSite);
    onHide();
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Site</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={editedSite.name || ''}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="url"
              name="url"
              value={editedSite.url || ''}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>RSS</Form.Label>
            <Form.Control
              type="url"
              name="rss"
              value={editedSite.rss || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Weight</Form.Label>
            <Form.Control
              type="number"
              name="weight"
              value={editedSite.weight || 0}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Image Host</Form.Label>
            <Form.Control
              type="text"
              name="imageHost"
              value={editedSite.image_host || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Logo</Form.Label>
            <Form.Control
              type="text"
              name="logo"
              value={editedSite.logo || ''}
              onChange={handleChange}
            />
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="primary" onClick={handleSubmit}>Save Changes</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const ToggleSiteActiveModal = ({ show, onHide, site, handleToggleActive }) => {
  return (
    <GoogleModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{site.active ? 'Deactivate' : 'Activate'} Site</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalText>
          Are you sure you want to {site.active ? 'deactivate' : 'activate'} the site "{site.name}"?
        </ModalText>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>
          Cancel
        </GoogleButton>
        <GoogleButton 
          variant={site.active ? "danger" : "success"} 
          onClick={() => {
            handleToggleActive(site.id);
            onHide();
          }}
        >
          {site.active ? (
            <>
              <FaToggleOff style={{ marginRight: '8px' }} />
              Deactivate
            </>
          ) : (
            <>
              <FaToggleOn style={{ marginRight: '8px' }} />
              Activate
            </>
          )}
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const AddSiteModal = ({ show, onHide, handleAddSite }) => {
  const [newSite, setNewSite] = useState({
    name: '',
    url: '',
    rss: '',
    weight: 0,
    image_host: '',
    logo: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewSite(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddSite(newSite);
    onHide();
    setNewSite({
      name: '',
      url: '',
      rss: '',
      weight: 0,
      image_host: '',
      logo: '',
    });
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Site</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={newSite.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="url"
              name="url"
              value={newSite.url}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>RSS</Form.Label>
            <Form.Control
              type="url"
              name="rss"
              value={newSite.rss}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Weight</Form.Label>
            <Form.Control
              type="number"
              name="weight"
              value={newSite.weight}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Image Host</Form.Label>
            <Form.Control
              type="text"
              name="image_host"
              value={newSite.image_host}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Logo</Form.Label>
            <Form.Control
              type="text"
              name="logo"
              value={newSite.logo}
              onChange={handleChange}
            />
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton as="button" className="btn btn-secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton as="button" className="btn btn-primary" onClick={handleSubmit}>Add Site</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const AddBillboardModal = ({ 
  show, 
  onHide, 
  createBillboard,
  onSubmitSuccess
}) => {
  const [formData, setFormData] = useState({
    link: '',
    active: false,
    company: '',
    renewal_date: '',
    isGlobal: false,
    countries: '',
    cities: '',
    states: ''
  });
  const [imageFile, setImageFile] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const isFormValid = () => {
    const requiredFields = ['link', 'company', 'renewalDate'];
    const isValid = requiredFields.every(field => formData[field] !== '');

    if (!formData.isGlobal) {
      const nonGlobalFields = ['countries', 'cities', 'states'];
      return isValid && nonGlobalFields.every(field => formData[field] !== '') && imageFile;
    }

    return isValid && imageFile;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) return;

    try {
      const billboardData = {
        ...formData,
        countries: formData.countries.split(',').map(item => item.trim()),
        cities: formData.cities.split(',').map(item => item.trim()),
        states: formData.states.split(',').map(item => item.trim()),
      };

      await createBillboard(billboardData, imageFile);
      onSubmitSuccess();
      onHide();
    } catch (error) {
      console.error('Error submitting billboard:', error);
    }
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Billboard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
            <Form.Label>Image</Form.Label>
            <Form.Control
              type="file"
              onChange={handleImageChange}
              accept="image/*"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Link</Form.Label>
            <Form.Control
              type="text"
              name="link"
              value={formData.link}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Active"
              name="active"
              checked={formData.active}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Company</Form.Label>
            <Form.Control
              type="text"
              name="company"
              value={formData.company}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Renewal Date</Form.Label>
            <Form.Control
              type="date"
              name="renewal_date"
              value={formData.renewal_date}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Global"
              name="isGlobal"
              checked={formData.isGlobal}
              onChange={handleChange}
            />
          </Form.Group>
          {!formData.isGlobal && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Countries (comma-separated)</Form.Label>
                <Form.Control
                  type="text"
                  name="countries"
                  value={formData.countries}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Cities (comma-separated)</Form.Label>
                <Form.Control
                  type="text"
                  name="cities"
                  value={formData.cities}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>States (comma-separated)</Form.Label>
                <Form.Control
                  type="text"
                  name="states"
                  value={formData.states}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </>
          )}
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton 
          variant="primary" 
          onClick={handleSubmit} 
          disabled={!isFormValid()}
        >
          Create
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const EditBillboardModal = ({ 
  show, 
  onHide, 
  updateBillboard, 
  billboard,
  onSubmitSuccess
}) => {
  const [formData, setFormData] = useState({
    link: '',
    active: false,
    company: '',
    renewal_date: '',
    isGlobal: false,
    countries: '',
    cities: '',
    states: ''
  });

  useEffect(() => {
    if (billboard) {
      setFormData({
        ...billboard,
        renewal_date: billboard.renewal_date ? new Date(billboard.renewal_date).toISOString().split('T')[0] : '',
        countries: billboard.countries?.join(', ') || '',
        cities: billboard.cities?.join(', ') || '',
        states: billboard.states?.join(', ') || '',
      });
    }
  }, [billboard]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const isFormValid = () => {
    const requiredFields = ['link', 'company', 'renewal_date'];
    const isValid = requiredFields.every(field => formData[field] !== '');

    if (!formData.isGlobal) {
      const nonGlobalFields = ['countries', 'cities', 'states'];
      return isValid && nonGlobalFields.every(field => formData[field] !== '');
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) return;

    try {
      const billboardData = {
        ...formData,
        countries: formData.countries.split(',').map(item => item.trim()),
        cities: formData.cities.split(',').map(item => item.trim()),
        states: formData.states.split(',').map(item => item.trim()),
      };

      await updateBillboard(billboard.id, billboardData);
      onSubmitSuccess();
      onHide();
    } catch (error) {
      console.error('Error updating billboard:', error);
    }
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Billboard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm onSubmit={handleSubmit}>

          <Form.Group className="mb-3">
            <Form.Label>Link</Form.Label>
            <Form.Control
              type="text"
              name="link"
              value={formData.link}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Active"
              name="active"
              checked={formData.active}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Company</Form.Label>
            <Form.Control
              type="text"
              name="company"
              value={formData.company}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Renewal Date</Form.Label>
            <Form.Control
              type="date"
              name="renewal_date"
              value={formData.renewal_date}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Global"
              name="isGlobal"
              checked={formData.isGlobal}
              onChange={handleChange}
            />
          </Form.Group>
          {!formData.isGlobal && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Countries (comma-separated)</Form.Label>
                <Form.Control
                  type="text"
                  name="countries"
                  value={formData.countries}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Cities (comma-separated)</Form.Label>
                <Form.Control
                  type="text"
                  name="cities"
                  value={formData.cities}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>States (comma-separated)</Form.Label>
                <Form.Control
                  type="text"
                  name="states"
                  value={formData.states}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </>
          )}
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton 
          variant="primary" 
          onClick={handleSubmit} 
          disabled={!isFormValid()}
        >
          Update
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const DeleteConfirmationModal = ({ show, onHide, onConfirm, billboardName }) => {
  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete the billboard for {billboardName}?
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="danger" onClick={onConfirm}>Delete</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const UpdateBillboardImageModal = ({ show, onHide, onSubmit, billboardName }) => {
  const [imageFile, setImageFile] = useState(null);

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (imageFile) {
      onSubmit(imageFile);
    }
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Billboard Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>New Image for {billboardName}</Form.Label>
            <Form.Control
              type="file"
              onChange={handleImageChange}
              accept="image/*"
              required
            />
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="primary" onClick={handleSubmit} disabled={!imageFile}>
          Update Image
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};
import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Form, Button, Container, Card, Alert, Spinner } from 'react-bootstrap';
import { login, setAuthToken } from './apiAuth';
import neoMambiLogo from "../../assets/images/neo_mambi_logo.png"

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Google Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: #f1f3f4;
    color: #202124;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const StyledCard = styled(Card)`
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  max-width: 450px;
  width: 100%;
`;

const Logo = styled.img`
  width: 120px;
  height: auto;
  margin-bottom: 16px;
`;

const StyledCardTitle = styled(Card.Title)`
  font-size: 24px;
  color: #202124;
  margin-bottom: 24px;
  font-weight: 400;
`;

const StyledForm = styled(Form)`
  .form-label {
    font-weight: 500;
    color: #5f6368;
  }

  .form-control {
    border-radius: 4px;
    border: 1px solid #dadce0;
    padding: 12px;
    font-size: 16px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
    }
  }
`;

const GoogleButton = styled(Button)`
  background-color: #1a73e8;
  border-color: #1a73e8;
  color: #fff;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.25px;
  padding: 12px 24px;
  font-size: 16px;
  
  &:hover, &:focus {
    background-color: #1765cc;
    border-color: #1765cc;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }
`;

const AuthComponent = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    const savedEmail = localStorage.getItem('lastUsedEmail');
    if (savedEmail) {
      setEmail(savedEmail);
      passwordRef.current?.focus();
    } else {
      emailRef.current?.focus();
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      const response = await login(email, password);
      if (response.data) {
        localStorage.setItem('lastUsedEmail', email);
        setAuthToken(response.data.firebase_auth_data.id_token);
        onLoginSuccess(response);
      } else {
        setError(response.message || 'Error de autenticación');
      }
    } catch (error) {
      setError(error.message || 'Error al intentar iniciar sesión');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <GlobalStyle />
      <StyledContainer>
        <StyledCard>
          <Card.Body className="text-center">
            <Logo src={neoMambiLogo} alt="Neo Mambi Logo" />
            <StyledCardTitle>Neo Mambi Admin</StyledCardTitle>
            {error && <Alert variant="danger">{error}</Alert>}
            <StyledForm onSubmit={handleLogin}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  ref={emailRef}
                />
              </Form.Group>

              <Form.Group className="mb-4" controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  ref={passwordRef}
                />
              </Form.Group>

              <GoogleButton variant="primary" type="submit" className="w-100" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    <span className="ms-2">Iniciando sesión...</span>
                  </>
                ) : (
                  'Iniciar sesión'
                )}
              </GoogleButton>
            </StyledForm>
          </Card.Body>
        </StyledCard>
      </StyledContainer>
    </>
  );
};

export default AuthComponent;
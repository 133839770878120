import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Button, Form, Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import styled, { createGlobalStyle } from 'styled-components';
import { FaFilter, FaEye } from 'react-icons/fa';
import { 
    getAllNotaryOrders, 
    fetchNotaryAgents, 
    assignNotaryAgent,
    updateNotaryOrderStatus,
    getAvailableNotaryStatuses,
    updatePrice } from './apiNotary';
import { sendNotificationToUser } from '../admin/apiAdmin';
    
import { 
    AssignAgentModal, 
    StatusModal, 
    NotifyCallModal,
    AlertModal,
    PriceModal  } from '../customViews/modals';
import { NotaryOrderSlidingPanel } from '../customViews/GoogleStyleSlidingPanel';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: #f1f3f4;
    color: #202124;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
`;

const StyledDataTable = styled(DataTable)`
  .rdt_Table {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  .rdt_TableHead {
    font-weight: 500;
    color: #5f6368;
    background-color: #f1f3f4;
  }

  .rdt_TableCol {
    padding: 16px;
  }

  .rdt_TableCell {
    font-size: 14px;
  }

  .rdt_Pagination {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
  }
`;

const SlidingPanel = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-100%'};
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  transition: right 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 999;
`;

const GoogleButton = styled(Button)`
  background-color: #fff;
  border-color: #dadce0;
  color: #3c4043;
  font-weight: 500;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
    border-color: #dadce0;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &:disabled {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #80868b;
  }
`;

const IconButton = styled(GoogleButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-right: 8px;
  
  svg {
    margin-right: 8px;
  }
`;

const FilterDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    font-weight: 500;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
      box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    }
  }

  .dropdown-menu {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
    border: none;
    border-radius: 4px;
  }
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircularProgress = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const NotaryAllocator = () => {
  const [orders, setOrders] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const [newStatus, setNewStatus] = useState('');
  const [statusNote, setStatusNote] = useState('');
  const panelRef = useRef(null);
  const [showNotifyCallModal, setShowNotifyCallModal] = useState(false);
  const [alertModal, setAlertModal] = useState({ show: false, title: '', message: '', variant: 'success' });
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [newPrice, setNewPrice] = useState('');
  const [filters, setFilters] = useState({
    name: '',
    email: '',
    phone: '',
    status: '',
  });

  const fetchOrdersData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        page: page.toString(),
        size: perPage.toString(),
        ...filters
      };

      const data = await getAllNotaryOrders(params);
      setOrders(data.content);
      setTotalRows(data.total_elements);
      setPage(data.number);
    } catch (error) {
      console.error('Error fetching notary orders:', error);
    } finally {
      setLoading(false);
    }
  }, [page, perPage, filters]);

  useEffect(() => {
    fetchOrdersData();
  }, [fetchOrdersData]);

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const clearFilters = () => {
    setFilters({
      name: '',
      email: '',
      phone: '',
      status: '',
    });
    setPage(0);
  };

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handlePerRowsChange = async (newPerPage, newPage) => {
    setPerPage(newPerPage);
  };

  const showAlert = useCallback((title, message, variant = 'success') => {
    setAlertModal({ show: true, title, message, variant });
  }, []);

  const closeAlert = useCallback(() => {
    setAlertModal({ show: false, title: '', message: '', variant: 'success' });
  }, []);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const closeNotifyCallModal = useCallback(() => {
    setShowNotifyCallModal(false);
    setSelectedOrder(null);
  }, []);

  const handleNotifyCall = useCallback(async () => {
    try {
      await sendNotificationToUser(
        selectedOrder.user_id,
        selectedOrder.id,
      );
      closeNotifyCallModal();
      showAlert('Success', 'Notification sent successfully', 'success');
    } catch (error) {
      console.error('Error al enviar la notificación:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Error desconocido al enviar la notificación';
      showAlert('Error', errorMessage, 'error');
    } finally {
      closeNotifyCallModal();
    }
  }, [selectedOrder, showAlert, closeNotifyCallModal]);

  const handleOpenStatusModal = async (order) => {
    setSelectedOrder(order);
    try {
      const statuses = await getAvailableNotaryStatuses(order.id);
      setAvailableStatuses(statuses);
      setShowStatusModal(true);
    } catch (error) {
      console.error('Error fetching available statuses:', error);
      showAlert('Error', 'Failed to fetch available statuses', 'error');
    }
  };

  const closeStatusModal = () => {
    setShowStatusModal(false);
    setSelectedOrder(null);
    setNewStatus('');
    setStatusNote('');
  };


  const handleChangeStatus = async () => {
    try {
      await updateNotaryOrderStatus(selectedOrder.id, newStatus);
      fetchOrdersData();
      closeStatusModal();
      showAlert('Success', 'Status updated successfully', 'success');
    } catch (error) {
      console.error('Error changing status:', error);
      showAlert('Error', 'Failed to change status', 'error');
    }
  };

  const openAssignModal = async () => {
    try {
      const agentsData = await fetchNotaryAgents();
      setAgents(agentsData);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching agents:', error);
      showAlert('Error', 'Failed to fetch agents', 'error');
    }
  };

  const closeAssignModal = () => {
    setShowModal(false);
    setSelectedAgent('');
  };

  const handleAssignAgent = async (notifyClient) => {
    if (!selectedAgent) {
      showAlert('Error', 'Please select an agent', 'error');
      return;
    }
  
    try {
      const selectedIds = selectedRows.map(row => row.id);
      await assignNotaryAgent(selectedIds, selectedAgent, notifyClient);
      fetchOrdersData();
      closeAssignModal();
      setSelectedRows([]);
      showAlert('Success', 'Agent assigned successfully', 'success');
    } catch (error) {
      console.error('Error assigning agent:', error);
      showAlert('Error', 'Failed to assign agent', 'error');
    }
  };

  const handleViewDetails = (row) => {
    setSelectedRow(row);
    setIsPanelOpen(true);
  };

  const closeSlidingPanel = () => {
    setIsPanelOpen(false);
    setSelectedRow(null);
  };

  const handleOutsideClick = (event) => {
    if (panelRef.current && !panelRef.current.contains(event.target)) {
      closeSlidingPanel();
    }
  };

  const handleOpenPriceModal = (order) => {
    setSelectedOrder(order);
    setNewPrice(order.price || '');
    setShowPriceModal(true);
  };

  const closePriceModal = () => {
    setShowPriceModal(false);
    setSelectedOrder(null);
    setNewPrice('');
  };

  const handleChangePrice = async () => {
    try {
      await updatePrice(selectedOrder.id, newPrice);
      fetchOrdersData();
      closePriceModal();
      showAlert('Success', 'Price updated successfully', 'success');
    } catch (error) {
      console.error('Error changing price:', error);
      showAlert('Error', 'Failed to update price', 'error');
    }
  };

  const columns = useMemo(() => [
    {
      name: 'Date',
      selector: row => new Date(row.created_at).toLocaleString(),
      sortable: true,
      wrap: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Phone',
      selector: row => row.phone,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Agent',
      selector: row => row.notary_contact ? row.notary_contact.name : 'Unassigned',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Price',
      selector: row => row.price ? `$${row.price}` : 'Not set',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Details',
      cell: row => (
        <IconButton onClick={() => handleViewDetails(row)}>
          <FaEye /> Details
        </IconButton>
      )
    }
  ], []);
  
  return (
    <>
      <GlobalStyle />
        <div className="d-flex justify-content-end gap-3 mb-4">
          <GoogleButton onClick={openAssignModal} disabled={selectedRows.length === 0}>
            Assign Agent
          </GoogleButton>
          <FilterDropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-filters">
              <FaFilter /> Filters
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-3" style={{ width: '300px' }}>
              <Form>
                <Form.Group className="mb-2">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={filters.name}
                    onChange={(e) => handleFilterChange('name', e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    value={filters.email}
                    onChange={(e) => handleFilterChange('email', e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    value={filters.phone}
                    onChange={(e) => handleFilterChange('phone', e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={filters.status}
                    onChange={(e) => handleFilterChange('status', e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="NEW">New</option>
                    <option value="IN_PROGRESS">In Progress</option>
                    <option value="FINISHED">Finished</option>
                    <option value="CONTACTED">Contacted</option>
                    <option value="CANCELLED">Cancelled</option>
                  </Form.Control>
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <GoogleButton onClick={clearFilters}>Clear Filters</GoogleButton>
                </div>
              </Form>
            </Dropdown.Menu>
          </FilterDropdown>
        </div>
            <StyledDataTable
              columns={columns}
              data={orders}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={perPage}
              paginationDefaultPage={page + 1}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              selectableRows
              onSelectedRowsChange={handleRowSelected}
              progressPending={loading}
              progressComponent={
                <LoadingOverlay>
                  <CircularProgress />
                </LoadingOverlay>
              }
              fixedHeader
              paginationComponentOptions={{
                rowsPerPageText: 'Rows per page:',
                rangeSeparatorText: 'of',
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: 'All'
              }}
              noDataComponent={<div style={{ padding: '24px', textAlign: 'center', color: '#5f6368' }}>No records available</div>}
              highlightOnHover
              pointerOnHover
          />

      <AssignAgentModal 
        show={showModal}
        onHide={closeAssignModal}
        agents={agents}
        selectedAgent={selectedAgent}
        setSelectedAgent={setSelectedAgent}
        handleAssignAgent={handleAssignAgent}
        selectedRowsCount={selectedRows.length}
      />

      <Overlay isOpen={isPanelOpen} onClick={handleOutsideClick} />
      <SlidingPanel isOpen={isPanelOpen} ref={panelRef}>
        <NotaryOrderSlidingPanel
          selectedRow={selectedRow}
          handleOpenStatusModal={handleOpenStatusModal}
          handleOpenPriceModal={handleOpenPriceModal}
          onClose={closeSlidingPanel}
        />
      </SlidingPanel>

      <StatusModal 
        show={showStatusModal}
        onHide={closeStatusModal}
        newStatus={newStatus}
        setNewStatus={setNewStatus}
        statusNote={statusNote}
        setStatusNote={setStatusNote}
        availableStatuses={availableStatuses}
        handleChangeStatus={handleChangeStatus}
      />

      <NotifyCallModal 
        show={showNotifyCallModal}
        onHide={closeNotifyCallModal}
        selectedOrder={selectedOrder}
        handleNotifyCall={handleNotifyCall}
      />

      <PriceModal 
        show={showPriceModal}
        onHide={closePriceModal}
        newPrice={newPrice}
        setNewPrice={setNewPrice}
        handleChangePrice={handleChangePrice}
      />

      <AlertModal
        show={alertModal.show}
        onHide={closeAlert}
        title={alertModal.title}
        message={alertModal.message}
        variant={alertModal.variant}
      />
    </>
  );
};

export default NotaryAllocator;
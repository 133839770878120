import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Button, Form, Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import styled, { createGlobalStyle } from 'styled-components';
import { FaFilter } from 'react-icons/fa';
import { NotaryOrderSlidingPanel } from '../customViews/GoogleStyleSlidingPanel';
import { 
  NotaryStatusModal, 
  PriceModal
} from '../customViews/modals';
import {
  getNotaryOrders,
  updateNotaryOrderStatus,
  getAvailableNotaryStatuses,
  updatePrice
} from './apiNotary';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: #f1f3f4;
    color: #202124;
    margin: 0;
    padding: 0;
    overflow: hidden;  // Prevent body scroll
  }
`;

const StyledDataTable = styled(DataTable)`
  .rdt_Table {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  .rdt_TableHead {
    font-weight: 500;
    color: #5f6368;
    background-color: #f1f3f4;
  }

  .rdt_TableCol {
    padding: 16px;
  }

  .rdt_TableCell {
    padding: 16px;
    font-size: 14px;
  }

  .rdt_Pagination {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
  }
`;

const SlidingPanel = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-100%'};
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  transition: right 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 999;
`;

const GoogleButton = styled(Button)`
  background-color: #1a73e8;
  border-color: #1a73e8;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 8px 24px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #1765cc;
    border-color: #1765cc;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }
`;

const FilterDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    font-weight: 500;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
      box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    }
  }

  .dropdown-menu {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
    border: none;
    border-radius: 4px;
  }
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircularProgress = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const NotaryOrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const [newStatus, setNewStatus] = useState('');
  const [newPrice, setNewPrice] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const panelRef = useRef(null);
  const [filters, setFilters] = useState({
    userId: '',
    status: '',
    email: '',
    name: '',
    phone: ''
  });

  const fetchOrdersData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        page: page.toString(),
        size: perPage.toString(),
        ...filters
      };

      const data = await getNotaryOrders(params);
      setOrders(data.content);
      setTotalRows(data.total_elements);
      setPage(data.number);
    } catch (error) {
      console.error('Error fetching notary orders:', error);
    } finally {
      setLoading(false);
    }
  }, [page, perPage, filters]);

  useEffect(() => {
    fetchOrdersData();
  }, [page, perPage, filters, fetchOrdersData]);

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const clearFilters = () => {
    setFilters({
      userId: '',
      status: '',
      email: '',
      name: '',
      phone: ''
    });
    setPage(0);
  };

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handlePerRowsChange = async (newPerPage, newPage) => {
    setPerPage(newPerPage);
  };

  const handleOpenStatusModal = async (order) => {
    setSelectedOrder(order);
    try {
      const statuses = await getAvailableNotaryStatuses(order.id);
      setAvailableStatuses(statuses);
      setShowStatusModal(true);
    } catch (error) {
      console.error('Error fetching available statuses:', error);
    }
  };

  const closeStatusModal = () => {
    setShowStatusModal(false);
    setSelectedOrder(null);
    setNewStatus('');
  };

  const handleChangeStatus = async () => {
    try {
      await updateNotaryOrderStatus(selectedOrder.id, newStatus);
      fetchOrdersData();
      closeStatusModal();
    } catch (error) {
      console.error('Error changing status:', error);
    }
  };

  const handleOpenPriceModal = (order) => {
    setSelectedOrder(order);
    setNewPrice(order.price || '');
    setShowPriceModal(true);
  };

  const closePriceModal = () => {
    setShowPriceModal(false);
    setSelectedOrder(null);
    setNewPrice('');
  };

  const handleChangePrice = async () => {
    try {
      await updatePrice(selectedOrder.id, newPrice);
      fetchOrdersData();
      closePriceModal();
    } catch (error) {
      console.error('Error changing price:', error);
    }
  };

  const handleRowClick = (row) => {
    if (!isPanelOpen) {
      setSelectedRow(row);
      setIsPanelOpen(true);
    }
  };

  const closeSlidingPanel = () => {
    setIsPanelOpen(false);
    setSelectedRow(null);
  };

  const handleOutsideClick = (event) => {
    if (panelRef.current && !panelRef.current.contains(event.target)) {
      closeSlidingPanel();
    }
  };

  const columns = useMemo(() => [
    {
      name: 'Date',
      selector: row => {
        const date = new Date(row.created_at);
        return date.toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
      },
      sortable: true,
      wrap: true,
      autoWidth: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      wrap: true,
      autoWidth: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      wrap: true,
      autoWidth: true,
    },
    {
      name: 'Phone',
      selector: row => row.phone,
      sortable: true,
      wrap: true,
      autoWidth: true,
    },
    {
      name: 'Service',
      selector: row => row.service,
      sortable: true,
      wrap: true,
      autoWidth: true,
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      wrap: true,
      autoWidth: true,
    },
    {
      name: 'Price',
      selector: row => row.price ? `$${row.price}` : 'Not set',
      sortable: true,
      wrap: true,
      autoWidth: true,
    }
  ], []);

  return (
    <>
      <GlobalStyle />
        <div className="d-flex justify-content-end align-items-center mb-4">
          <div className="d-flex">
            <FilterDropdown>
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-filters">
                <FaFilter /> Filters
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-3" style={{ width: '300px' }}>
                <Form>
                  <Form.Group className="mb-2">
                    <Form.Label>User ID</Form.Label>
                    <Form.Control
                      type="text"
                      value={filters.userId}
                      onChange={(e) => handleFilterChange('userId', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      as="select"
                      value={filters.status}
                      onChange={(e) => handleFilterChange('status', e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="NEW">New</option>
                      <option value="IN_PROGRESS">In Progress</option>
                      <option value="FINISHED">Finished</option>
                      <option value="CANCELLED">Cancelled</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      value={filters.email}
                      onChange={(e) => handleFilterChange('email', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={filters.name}
                      onChange={(e) => handleFilterChange('name', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      value={filters.phone}
                      onChange={(e) => handleFilterChange('phone', e.target.value)}
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-end">
                    <GoogleButton onClick={clearFilters}>Clear Filters</GoogleButton>
                  </div>
                </Form>
              </Dropdown.Menu>
            </FilterDropdown>
          </div>
        </div>

            <StyledDataTable
              columns={columns}
              data={orders}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={perPage}
              paginationDefaultPage={page + 1}
              onChangePage={handlePageChange}
              paginationRowsPerPageOptions={[10, 15, 35, 50, 100]}
              onChangeRowsPerPage={handlePerRowsChange}
              noDataComponent={<div style={{ padding: '24px', textAlign: 'center', color: '#5f6368' }}>No records available</div>}
              progressPending={loading}
              progressComponent={
                <LoadingOverlay>
                  <CircularProgress />
                </LoadingOverlay>
              }
              fixedHeader
              paginationComponentOptions={{
                rowsPerPageText: 'Rows per page:',
                rangeSeparatorText: 'of',
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: 'All'
              }}
              selectableRows={false}
              highlightOnHover
              pointerOnHover
              onRowClicked={handleRowClick}
            />

      <Overlay isOpen={isPanelOpen} onClick={handleOutsideClick} />
      <SlidingPanel isOpen={isPanelOpen} ref={panelRef}>
        <NotaryOrderSlidingPanel
          selectedRow={selectedRow}
          handleOpenStatusModal={handleOpenStatusModal}
          handleOpenPriceModal={handleOpenPriceModal}
          onClose={closeSlidingPanel}
        />
      </SlidingPanel>

      <NotaryStatusModal 
        show={showStatusModal}
        onHide={closeStatusModal}
        newStatus={newStatus}
        setNewStatus={setNewStatus}
        availableStatuses={availableStatuses}
        handleChangeStatus={handleChangeStatus}
      />

      <PriceModal 
        show={showPriceModal}
        onHide={closePriceModal}
        newPrice={newPrice}
        setNewPrice={setNewPrice}
        handleChangePrice={handleChangePrice}
      />
    </>
  );
};

export default NotaryOrderManagement;
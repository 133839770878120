import axios from 'axios';
import config from '../config';
import { getAuthToken, clearAuthToken } from './auth/apiAuth';

const api = axios.create({
  baseURL: config.apiBaseUrl,
});

api.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      clearAuthToken();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default api;
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container, Button, Form, Dropdown, Badge } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { FaFilter, FaCheck, FaTimes } from 'react-icons/fa';
import { UserManagementSlidingPanel } from '../customViews/GoogleStyleSlidingPanel';
import { 
  CreateUserModal, 
  EditNameModal, 
  ManageRolesModal, 
  ContactInfoModal,
  InvalidateAccountModal
} from '../customViews/modals';
import {
  fetchUsers,
  createUser,
  updateUserName,
  addUserRole,
  removeUserRole,
  updateUserContactInfo,
  invalidateAccount
} from './apiAdmin';

const PageContainer = styled(Container)`
  width: 100%;
  max-width: none;
  padding: 24px;
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
`;

const StyledDataTable = styled(DataTable)`
  .rdt_Table {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  .rdt_TableHead {
    font-weight: 500;
    color: #5f6368;
    background-color: #f1f3f4;
  }

  .rdt_TableCol {
    padding: 16px;
  }

  .rdt_TableCell {
    padding: 16px;
    font-size: 14px;
  }

  .rdt_Pagination {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;
  }
`;

const SlidingPanel = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-100%'};
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  transition: right 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 999;
`;

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;  // Add vertical scroll
  padding: 0 16px;  // Add some padding
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
`;

const CircularProgress = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const GoogleButton = styled(Button)`
  background-color: #fff;
  border-color: #dadce0;
  color: #3c4043;
  font-weight: 500;
  text-transform: none;
  letter-spacing: normal;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #3c4043;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }
`;

const FilterDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    font-weight: 500;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
      box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    }
  }

  .dropdown-menu {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
    border: none;
    border-radius: 4px;
  }
`;

const StatusBadge = styled(Badge)`
  font-size: 0.8rem;
  padding: 0.3em 0.6em;
`;

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [showEditNameModal, setShowEditNameModal] = useState(false);
  const [showManageRolesModal, setShowManageRolesModal] = useState(false);
  const [showContactInfoModal, setShowContactInfoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [alertModal, setAlertModal] = useState({ show: false, title: '', message: '', variant: 'success' });
  const [showInvalidateAccountModal, setShowInvalidateAccountModal] = useState(false);

  const [filters, setFilters] = useState({
    name: '',
    email: '',
    role: ''
  });

  const fetchUsersData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        page: page.toString(),
        size: perPage.toString(),
        ...filters
      };

      const data = await fetchUsers(params);
      setUsers(data.content);
      setTotalRows(data.totalElements);
      setPage(data.number);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  }, [page, perPage, filters]);

  useEffect(() => {
    fetchUsersData();
  }, [page, perPage, filters, fetchUsersData]);

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const clearFilters = () => {
    setFilters({
      name: '',
      email: '',
      role: ''
    });
    setPage(0);
  };

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handlePerRowsChange = async (newPerPage, newPage) => {
    setPerPage(newPerPage);
  };

  const handleRowClick = (row) => {
    if (!isPanelOpen) {
      setSelectedUser(row);
      setIsPanelOpen(true);
    }
  };

  const showAlert = useCallback((title, message, variant = 'success') => {
    setAlertModal({ show: true, title, message, variant });
  }, []);

  const handleCreateUser = async (userData) => {
    try {
      await createUser(userData);
      fetchUsersData();
      setShowCreateUserModal(false);
      showAlert('Success', 'User created successfully');
    } catch (error) {
      showAlert('Error', 'Failed to create user', 'danger');
    }
  };

  const handleUpdateName = async (name) => {
    try {
      await updateUserName(selectedUser.id, name);
      fetchUsersData();
      setShowEditNameModal(false);
      showAlert('Success', 'User name updated successfully');
      
      setSelectedUser(prevUser => ({ ...prevUser, name }));
    } catch (error) {
      showAlert('Error', 'Failed to update user name', 'danger');
    }
  };

  const handleAddRole = async (role) => {
    try {
      await addUserRole(selectedUser.id, role);
      fetchUsersData();
      showAlert('Success', 'Role added successfully');
      
      setSelectedUser(prevUser => ({
        ...prevUser,
        roles: [...prevUser.roles, role]
      }));
    } catch (error) {
      showAlert('Error', 'Failed to add role', 'danger');
    }
  };

  const handleRemoveRole = async (role) => {
    try {
      await removeUserRole(selectedUser.id, role);
      fetchUsersData();
      showAlert('Success', 'Role removed successfully');
      
      setSelectedUser(prevUser => ({
        ...prevUser,
        roles: prevUser.roles.filter(r => r !== role)
      }));
    } catch (error) {
      showAlert('Error', 'Failed to remove role', 'danger');
    }
  };

  const handleUpdateContactInfo = async (contactInfo) => {
    try {
      await updateUserContactInfo(selectedUser.id, contactInfo);
      fetchUsersData();
      setShowContactInfoModal(false);
      showAlert('Success', 'Contact information updated successfully');
      
      setSelectedUser(prevUser => ({
        ...prevUser,
        contactInfo
      }));
    } catch (error) {
      showAlert('Error', 'Failed to update contact information', 'danger');
    }
  };

  const handleInvalidateAccount = useCallback((user) => {
    const confirmInvalidation = window.confirm(`Are you sure you want to invalidate the account for ${user.name}? This action cannot be undone.`);
    
    if (confirmInvalidation) {
      setLoading(true);
      invalidateAccount(user.auth_id)
        .then(() => {
          showAlert('Success', 'Account invalidated successfully', 'success');
          fetchUsersData(); // Refresh the user list
        })
        .catch((error) => {
          showAlert('Error', 'Failed to invalidate account: ' + error.message, 'danger');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [showAlert, fetchUsersData]);

  const confirmInvalidateAccount = useCallback(() => {
    if (selectedUser) {
      setLoading(true);
      invalidateAccount(selectedUser.id)
        .then(() => {
          showAlert('Success', 'Account invalidated successfully', 'success');
          fetchUsersData(); // Refresh the user list
        })
        .catch((error) => {
          showAlert('Error', 'Failed to invalidate account: ' + error.message, 'danger');
        })
        .finally(() => {
          setLoading(false);
          setShowInvalidateAccountModal(false);
        });
    }
  }, [selectedUser, showAlert, fetchUsersData]);

  const columns = useMemo(() => [
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Roles',
      selector: row => row.roles.join(', '),
      sortable: true,
      wrap: true,
    },
    {
        name: 'Disable',
        selector: row => row.account_disable,
        sortable: true,
        wrap: true,
        cell: row => (
          <StatusBadge bg={row.account_disable ? 'danger' : 'success'}>
            {row.account_disable ? <><FaTimes /> Disabled</> : <><FaCheck /> Enabled</>}
          </StatusBadge>
        ),
      },
  ], []);

  return (
    <>
      <PageContainer>
        <div className="d-flex justify-content-end gap-3 mb-4">
          <GoogleButton onClick={() => setShowCreateUserModal(true)}>
            Create User
          </GoogleButton>
          <FilterDropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-filters">
              <FaFilter /> Filters
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-3" style={{ width: '300px' }}>
              <Form>
                <Form.Group className="mb-2">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={filters.name}
                    onChange={(e) => handleFilterChange('name', e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    value={filters.email}
                    onChange={(e) => handleFilterChange('email', e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    type="text"
                    value={filters.role}
                    onChange={(e) => handleFilterChange('role', e.target.value)}
                  />
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <GoogleButton onClick={clearFilters}>Clear Filters</GoogleButton>
                </div>
              </Form>
            </Dropdown.Menu>
          </FilterDropdown>
        </div>

        <TableContainer>
        <ScrollableContent>
            {loading && (
              <LoadingOverlay>
                <CircularProgress />
              </LoadingOverlay>
            )}
          <StyledDataTable
            columns={columns}
            data={users}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            paginationDefaultPage={page + 1}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            selectableRows={false}
            highlightOnHover
            pointerOnHover
            onRowClicked={handleRowClick}
            progressPending={loading}
            progressComponent={<div />}
          />
          </ScrollableContent>
        </TableContainer>
      </PageContainer>

      <Overlay isOpen={isPanelOpen} onClick={() => setIsPanelOpen(false)} />
      <SlidingPanel isOpen={isPanelOpen}>
        <UserManagementSlidingPanel
            selectedUser={selectedUser}
            openEditNameModal={() => setShowEditNameModal(true)}
            openManageRolesModal={() => setShowManageRolesModal(true)}
            openContactInfoModal={() => setShowContactInfoModal(true)}
            handleInvalidateAccount={handleInvalidateAccount}
            onClose={() => setIsPanelOpen(false)}
            />
      </SlidingPanel>

      <CreateUserModal
        show={showCreateUserModal}
        onHide={() => setShowCreateUserModal(false)}
        handleCreateUser={handleCreateUser}
      />

    <EditNameModal
        show={showEditNameModal}
        onHide={() => setShowEditNameModal(false)}
        currentName={selectedUser?.name}
        handleUpdateName={handleUpdateName}
      />

      <ManageRolesModal
        show={showManageRolesModal}
        onHide={() => setShowManageRolesModal(false)}
        currentRoles={selectedUser?.roles}
        handleAddRole={handleAddRole}
        handleRemoveRole={handleRemoveRole}
      />

      <ContactInfoModal
        show={showContactInfoModal}
        onHide={() => setShowContactInfoModal(false)}
        currentContactInfo={selectedUser?.contact_info}
        handleUpdateContactInfo={handleUpdateContactInfo}
      />

    <InvalidateAccountModal
        show={showInvalidateAccountModal}
        onHide={() => setShowInvalidateAccountModal(false)}
        onConfirm={confirmInvalidateAccount}
        userName={selectedUser?.name || ''}
        />
    </>
  );
};

export default UserManagement;
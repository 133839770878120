import api from '../apiBase';

export const fetchBillboards = async (params) => {
  try {
    const response = await api.get('/api/billboard', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching billboards:', error);
    throw error;
  }
};

export const createBillboard = async (billboardData, image) => {
  try {
    const formData = new FormData();
    
    // Añadir los datos del billboard como una parte JSON
    formData.append('billboard', new Blob([JSON.stringify(billboardData)], {
      type: 'application/json'
    }));
    
    // Añadir la imagen
    formData.append('image', image);

    const response = await api.post('/api/billboard', formData, {
      headers: { 
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating billboard:', error);
    throw error;
  }
};

export const updateBillboard = async (billboardId, formData) => {
  try {
    const response = await api.put(`/api/billboard/${billboardId}`, formData);
    return response.data;
  } catch (error) {
    console.error('Error updating billboard:', error);
    throw error;
  }
};

export const deleteBillboard = async (billboardId) => {
  try {
    await api.delete(`/api/billboard/${billboardId}`);
  } catch (error) {
    console.error('Error deleting billboard:', error);
    throw error;
  }
};

export const uploadBillboardImage = async (billboardId, imageFile) => {
  try {
    const formData = new FormData();
    formData.append('image', imageFile);

    const response = await api.put(`/api/billboard/${billboardId}/image`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading billboard image:', error);
    throw error;
  }
};

export const getBillboard = async (billboardId) => {
  try {
    const response = await api.get(`/api/billboard/${billboardId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching billboard:', error);
    throw error;
  }
};

export const updateBillboardStatus = async (billboardId, active) => {
  try {
    const response = await api.put(`/api/billboard/${billboardId}/status`, { active });
    return response.data;
  } catch (error) {
    console.error('Error updating billboard status:', error);
    throw error;
  }
};

export default api;
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Nav, Card, Spinner, Button, Dropdown } from 'react-bootstrap';
import { getClientModules } from './admin/apiAdmin';
import config from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars, faCube } from '@fortawesome/free-solid-svg-icons';
import HealthInsuranceAgent from './insurance/healthInsuranceAgent'
import HealthInsuranceAllocator from './insurance/HealthInsuranceAllocator';
import NotaryOrderManagement from './notary/NotaryOrderManagement';
import NotaryAllocator from './notary/NotaryAllocator';
import NotaryStatistics from './notary/NotaryStatistics';
import UserManagement from './admin/UserManagement';
import SiteManagement from './sites/SiteManagement';
import BillboardManager from './billboard/BillboardManager';
import CookBookManager from '../components/cookbook/CookBookManager';
import TaxesAgent from '../components/taxes/TaxesAgent';
import TaxesAllocator from '../components/taxes/TaxesAllocator';
import IncidentReport from '../components/incidentReport/IncidentReport';
import IncidentPeople from '../components/incidentPeople/IncidentPeople';
import Incident from '../components/incident/Incident';
import neoMambiLogo from "../assets/images/neo_mambi_logo.png"

const GoogleStyleWrapper = styled.div`
  font-family: 'Google Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background-color: #f1f3f4;
  color: #202124;
`;

const Header = styled.header`
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15);
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  h1 {
    font-size: 22px;
    font-weight: 400;
    margin: 0;
    margin-left: 16px;
  }
`;

const Logo = styled.img`
  width: 40px;
  height: auto;
`;

const GoogleButton = styled(Button)`
  background-color: #fff;
  color: #5f6368;
  border: 1px solid #dadce0;
  border-radius: 24px;
  padding: 8px 24px;
  font-size: 14px;
  font-weight: 500;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }
`;

const Sidebar = styled(Nav)`
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15);
  width: 256px;
  transition: all 0.3s;
  padding-top: 8px;
`;

const SidebarLink = styled(Nav.Link)`
  color: #3c4043;
  padding: 0 24px;
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
  }
  
  &.active {
    color: #1a73e8;
    background-color: #e8f0fe;
  }
`;

const SidebarGroupTitle = styled.h6`
  font-size: 12px;
  font-weight: 500;
  color: #5f6368;
  padding: 0 24px;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const UserButton = styled(Dropdown.Toggle)`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  color: #5f6368;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
  }
`;

const UserAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
`;


const DashboardComponent = ({ onLogout }) => {
  const [client, setClient] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [activeModule, setActiveModule] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [groupedModules, setGroupedModules] = useState({});
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const fetchClientModules = async () => {
      try {
        const response = await getClientModules(config.clientId);
        setClient(response);
        if (response && response.modules && response.modules.length > 0) {
          organizeModulesByGroup(response.modules);
        }
        const savedEmail = localStorage.getItem('lastUsedEmail');
        setUserEmail(savedEmail || 'usuario@ejemplo.com');
      } catch (error) {
        setError(error.message || 'Error al obtener los módulos del cliente');
      } finally {
        setLoading(false);
      }
    };

    fetchClientModules();
  }, []);


  const organizeModulesByGroup = (modules) => {
    const grouped = modules.reduce((acc, module) => {
      const group = module.group || 'Sin grupo';
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(module);
      return acc;
    }, {});

    // Ordenar los grupos alfabéticamente
    const sortedGroups = Object.keys(grouped).sort();
    const sortedGroupedModules = {};
    sortedGroups.forEach(group => {
      sortedGroupedModules[group] = grouped[group];
    });

    setGroupedModules(sortedGroupedModules);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  if (loading) {
    return (
      <GoogleStyleWrapper className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Cargando...</span>
        </Spinner>
      </GoogleStyleWrapper>
    );
  }
  if (error) {
    return (
      <GoogleStyleWrapper className="d-flex justify-content-center align-items-center vh-100">
        <Card className="text-center" style={{ maxWidth: '400px' }}>
          <Card.Header className="bg-danger text-white">Error</Card.Header>
          <Card.Body>
            <Card.Text>{error}</Card.Text>
            <GoogleButton onClick={onLogout}>
              Cerrar sesión
            </GoogleButton>
          </Card.Body>
        </Card>
      </GoogleStyleWrapper>
    );
  }

  return (
    <Router>
      <GoogleStyleWrapper className="vh-100 d-flex flex-column">
        <Header>
          <HeaderTitle>
            <Button variant="link" onClick={toggleSidebar} className="p-0 me-3 text-dark">
              <FontAwesomeIcon icon={faBars} />
            </Button>
            <h1>Dashboard</h1>
          </HeaderTitle>
          <Dropdown>
            <UserButton variant="success" id="dropdown-basic">
              <UserAvatar src={neoMambiLogo} alt="User Avatar" />
              {userEmail}
            </UserButton>

            <Dropdown.Menu>
              <Dropdown.Item onClick={onLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                Cerrar sesión
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Header>

        <div className="flex-grow-1 d-flex overflow-hidden">
          <Sidebar className={`flex-column ${isSidebarVisible ? 'd-flex' : 'd-none'}`}>
            {Object.entries(groupedModules).map(([group, modules]) => (
              <div key={group}>
                <SidebarGroupTitle>{group}</SidebarGroupTitle>
                {modules.map((module) => (
                  <Nav.Item key={module.module_id}>
                    <SidebarLink
                      as={Link}
                      to={module.route}
                      className={activeModule === module.module_id ? 'active' : ''}
                      onClick={() => setActiveModule(module.module_id)}
                    >
                      <FontAwesomeIcon icon={faCube} className="me-3" />
                      <span>{module.name}</span>
                    </SidebarLink>
                  </Nav.Item>
                ))}
              </div>
            ))}
          </Sidebar>

          <div className="flex-grow-1 overflow-auto">
            <div className="h-100 p-4">
              <Routes>
                <Route path="/" element={
                  <div className="container-fluid h-100 d-flex align-items-center justify-content-center">
                    <Card className="shadow-sm" style={{maxWidth: '400px'}}>
                      <Card.Body className="text-center">
                        <Logo src={neoMambiLogo} alt="Neo Mambi Logo" className="mb-4" style={{width: '80px'}} />
                        <Card.Title className="mb-3" style={{fontSize: '24px', fontWeight: '400'}}>Bienvenido al Dashboard</Card.Title>
                        <Card.Text className="text-muted">Selecciona un módulo para comenzar</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                } />
                {client && client.modules.map((module) => (
                  <Route 
                    key={module.module_id} 
                    path={module.route} 
                    element={<ModuleContent module={module} />} 
                  />
                ))}
              </Routes>
            </div>
          </div>
        </div>
      </GoogleStyleWrapper>
    </Router>
  );
};

const ModuleContent = ({ module }) => {
  return (
    <div className="h-100 d-flex flex-column">
      {renderModuleContent(module)}
    </div>
  );
};

const renderModuleContent = (module) => {
  switch (module.route) {
    case '/health_insurance_agent':
      return <HealthInsuranceAgent />;
    case '/health_insurance_allocator':
      return <HealthInsuranceAllocator />;
    case '/notary_agent':
      return <NotaryOrderManagement />;
    case '/notary_allocator':
        return <NotaryAllocator />;
    case '/notary_statistics':
      return <NotaryStatistics />;
    case '/worker_manager':
      return <UserManagement />;
    case '/sites':
      return <SiteManagement />;
    case '/billboard':
      return <BillboardManager />;
    case '/cookbook':
      return <CookBookManager />;
    case '/taxes':
      return <TaxesAgent />;
    case '/taxes_allocator':
      return <TaxesAllocator />;
    case '/incident_report':
      return <IncidentReport />;
    case '/incident_people':
      return <IncidentPeople />;
    case '/incident':
      return <Incident />;
    default:
      return (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <p>Contenido del módulo {module.module_id}</p>
        </div>
      );
  }
};

export default DashboardComponent;
import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Modal, Form, ListGroup, Button } from 'react-bootstrap';
import { FaEdit, FaTrash, FaCircle, FaRegCircle } from 'react-icons/fa';

const GoogleModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.3), 0 4px 8px 3px rgba(60,64,67,0.15);
  }

  .modal-header {
    border-bottom: 1px solid #e8eaed;
    padding: 24px 24px 20px;
  }

  .modal-title {
    font-size: 22px;
    color: #202124;
    font-weight: 400;
  }

  .modal-body {
    padding: 24px;
  }

  .modal-footer {
    border-top: 1px solid #e8eaed;
    padding: 20px 24px 24px;
  }
`;

const GoogleForm = styled(Form)`
  .form-label {
    font-weight: 500;
    color: #5f6368;
    margin-bottom: 8px;
  }

  .form-control {
    border-radius: 4px;
    border: 1px solid #dadce0;
    padding: 8px 12px;
    font-size: 14px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
    }
  }

  .form-select {
    border-radius: 4px;
    border: 1px solid #dadce0;
    padding: 8px 12px;
    font-size: 14px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
    }
  }
`;

const GoogleListGroup = styled(ListGroup)`
  .list-group-item {
    border: none;
    background-color: #f1f3f4;
    margin-bottom: 8px;
    border-radius: 4px;
    padding: 12px;
  }
`;

const GoogleButton = styled(Button)`
  border-radius: 4px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 24px;

  &.btn-primary {
    background-color: #1a73e8;
    border-color: #1a73e8;
    color: #fff;
    
    &:hover, &:focus {
      background-color: #1765cc;
      border-color: #1765cc;
    }

    &:disabled {
      background-color: #8ab4f8;
      border-color: #8ab4f8;
    }
  }

  &.btn-secondary {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
    }
  }

  &.btn-danger {
    background-color: #ea4335;
    border-color: #ea4335;
    color: #fff;
    
    &:hover, &:focus {
      background-color: #d93025;
      border-color: #d93025;
    }
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    border-bottom: none;
    padding: 20px 20px 0;
  }

  .modal-body {
    padding: 20px;
  }

  .modal-footer {
    border-top: none;
    padding: 0 20px 20px;
  }
`;

const ActionButton = styled(GoogleButton)`
  padding: 4px 8px;
  font-size: 14px;
`;

const TimelineContainer = styled.div`
  padding: 20px;
  max-height: 60vh;
  overflow-y: auto;
`;

const TimelineItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    left: 11px;
    top: 24px;
    bottom: -24px;
    width: 2px;
    background: #dadce0;
  }

  &:last-child:after {
    display: none;
  }
`;

const TimelineIcon = styled.div`
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 3px;
  z-index: 1;
`;

const TimelineContent = styled.div`
  margin-left: 20px;
  padding: 10px;
  background-color: #f1f3f4;
  border-radius: 8px;
  flex-grow: 1;
`;

const TimelineDate = styled.p`
  font-size: 0.9em;
  color: #5f6368;
  margin-bottom: 5px;
`;

const TimelineTitle = styled.h5`
  font-size: 1em;
  color: #1a73e8;
  margin-bottom: 5px;
`;

const TimelineNote = styled.p`
  font-size: 0.9em;
  color: #3c4043;
  margin-bottom: 0;
`;


// Ahora, actualizamos cada componente modal para usar estos nuevos estilos

export const NotesModal = ({ show, onHide, selectedInsurance, newNote, setNewNote, handleAddNote, openEditNoteModal, handleDeleteNote }) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(newNote.trim().length > 0);
  }, [newNote]);

  return (
    <GoogleModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Notes
          <small className="text-muted ms-2">({selectedInsurance?.notes?.length || 0} notes)</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleListGroup className="mb-3" style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {selectedInsurance?.notes?.map((note, index) => (
            <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
              <div>
                <p className="mb-0">{note.text}</p>
                <small className="text-muted">{new Date(note.date).toLocaleString()}</small>
              </div>
              <div>
                <ActionButton 
                  variant="secondary" 
                  className="me-2"
                  onClick={() => openEditNoteModal(index, note.text)}
                  title="Edit note"
                >
                  <FaEdit />
                </ActionButton>
                <ActionButton 
                  variant="danger" 
                  onClick={() => handleDeleteNote(index)}
                  title="Delete note"
                >
                  <FaTrash />
                </ActionButton>
              </div>
            </ListGroup.Item>
          ))}
        </GoogleListGroup>
        <GoogleForm>
          <Form.Group>
            <Form.Label>Add New Note</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              isInvalid={!isValid}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a note.
            </Form.Control.Feedback>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Close</GoogleButton>
        <GoogleButton variant="primary" onClick={handleAddNote} disabled={!isValid}>Add Note</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const EditNoteModal = ({ show, onHide, editingNote, setEditingNote, handleEditNote }) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(editingNote.text.trim().length > 0);
  }, [editingNote.text]);

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              value={editingNote.text}
              onChange={(e) => setEditingNote({ ...editingNote, text: e.target.value })}
              isInvalid={!isValid}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a note.
            </Form.Control.Feedback>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="primary" onClick={handleEditNote} disabled={!isValid}>Save Changes</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const StatusModal = ({ 
    show, 
    onHide, 
    newStatus, 
    setNewStatus, 
    statusNote, 
    setStatusNote, 
    availableStatuses, 
    handleChangeStatus 
  }) => {
    const [isValid, setIsValid] = useState(false);
  
    useEffect(() => {
      setIsValid(newStatus !== "" && statusNote.trim().length > 0);
    }, [newStatus, statusNote]);
  
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>New Status</Form.Label>
              <Form.Select
                value={newStatus ? newStatus.code : ""}
                onChange={(e) => {
                  const selectedStatus = availableStatuses.find(status => status.code === e.target.value);
                  setNewStatus(selectedStatus || null);
                }}
                isInvalid={newStatus === null}
              >
                <option value="">Select a status</option>
                {availableStatuses.map((status) => (
                  <option key={status.code} value={status.code}>
                    {status.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please select a status.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Note</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={statusNote}
                onChange={(e) => setStatusNote(e.target.value)}
                isInvalid={statusNote.trim().length === 0}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a note.
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>Close</Button>
          <Button variant="primary" onClick={() => handleChangeStatus(newStatus)} disabled={!isValid}>
            Change Status
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

export const ContactModal = ({ show, onHide, updateInfoBody, setUpdateInfoBody, handleUpdateContactInfo }) => {
  console.log("testeando", updateInfoBody);
  const [isValid, setIsValid] = useState(false);
  const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

  // Provide default values if updateInfoBody is undefined
  const safeUpdateInfoBody = useMemo(() => {
    return updateInfoBody || { contact_info: {}, address: {} };
  }, [updateInfoBody]);

  useEffect(() => {
    if (safeUpdateInfoBody?.contact_info && safeUpdateInfoBody?.address) {
      const { first_name, last_name, email, phone_number, contact_time, contact_days } = safeUpdateInfoBody.contact_info;
      const { address, postal_code } = safeUpdateInfoBody.address;
      setIsValid(
        first_name?.trim().length > 0 &&
        last_name?.trim().length > 0 &&
        email?.trim().length > 0 &&
        phone_number?.trim().length > 0 &&
        contact_time?.trim().length > 0 &&
        contact_days?.trim().length > 0 &&
        address?.trim().length > 0 &&
        postal_code?.trim().length > 0
      );
    } else {
      setIsValid(false);
    }
  }, [safeUpdateInfoBody]);

  const handleContactDaysChange = (day) => {
    const currentDays = safeUpdateInfoBody.contact_info?.contact_days?.split(',') || [];
    const updatedDays = currentDays.includes(day)
      ? currentDays.filter(d => d !== day)
      : [...currentDays, day];
    setUpdateInfoBody({
      ...safeUpdateInfoBody,
      contact_info: {
        ...safeUpdateInfoBody.contact_info,
        contact_days: updatedDays.join(',')
      }
    });
  };

  const convert12To24 = (time12h) => {
    if (!time12h) return '';
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    
    hours = parseInt(hours, 10);
    
    if (hours === 12) {
      hours = 0;
    }
    
    if (modifier === 'PM') {
      hours += 12;
    }
    
    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  };

  const convert24To12 = (time24h) => {
    if (!time24h) return '';
    const [hours, minutes] = time24h.split(':');
    let period = 'AM';
    let hours12 = parseInt(hours, 10);
    if (hours12 >= 12) {
      period = 'PM';
      if (hours12 > 12) hours12 -= 12;
    }
    if (hours12 === 0) hours12 = 12;
    return `${hours12.toString().padStart(2, '0')}:${minutes} ${period}`;
  };

  const handleContactTimeChange = (field, value) => {
    const [startTime, endTime] = safeUpdateInfoBody.contact_info?.contact_time?.split(' - ') || ['', ''];
    const updatedTime = field === 'start' 
      ? `${convert24To12(value)} - ${endTime}`
      : `${startTime} - ${convert24To12(value)}`;
    setUpdateInfoBody({
      ...safeUpdateInfoBody,
      contact_info: {
        ...safeUpdateInfoBody.contact_info,
        contact_time: updatedTime
      }
    });
  };

  const handleInputChange = (field, value, section) => {
    setUpdateInfoBody({
      ...safeUpdateInfoBody,
      [section]: {
        ...safeUpdateInfoBody[section],
        [field]: value
      }
    });
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Contact Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group className="mb-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              value={safeUpdateInfoBody?.contact_info?.first_name || ''}
              onChange={(e) => handleInputChange('first_name', e.target.value, 'contact_info')}
              isInvalid={safeUpdateInfoBody?.contact_info?.first_name?.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a first name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              value={safeUpdateInfoBody?.contact_info?.last_name || ''}
              onChange={(e) => handleInputChange('last_name', e.target.value, 'contact_info')}
              isInvalid={safeUpdateInfoBody?.contact_info?.last_name?.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a last name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={safeUpdateInfoBody?.contact_info?.email || ''}
              onChange={(e) => handleInputChange('email', e.target.value, 'contact_info')}
              isInvalid={safeUpdateInfoBody?.contact_info?.email?.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter an email address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              value={safeUpdateInfoBody?.address?.address || ''}
              onChange={(e) => handleInputChange('address', e.target.value, 'address')}
              isInvalid={safeUpdateInfoBody?.address?.address?.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter an address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control
              type="text"
              value={safeUpdateInfoBody?.address?.postal_code || ''}
              onChange={(e) => handleInputChange('postal_code', e.target.value, 'address')}
              isInvalid={safeUpdateInfoBody?.address?.postal_code?.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a postal code.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="tel"
              value={safeUpdateInfoBody?.contact_info?.phone_number || ''}
              onChange={(e) => handleInputChange('phone_number', e.target.value, 'contact_info')}
              isInvalid={safeUpdateInfoBody?.contact_info?.phone_number?.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a phone number.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Contact Days</Form.Label>
            {daysOfWeek.map((day) => (
              <Form.Check
                key={day}
                type="checkbox"
                label={day}
                checked={safeUpdateInfoBody?.contact_info?.contact_days?.split(',').includes(day)}
                onChange={() => handleContactDaysChange(day)}
              />
            ))}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Contact Time</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="time"
                value={safeUpdateInfoBody?.contact_info?.contact_time ? convert12To24(safeUpdateInfoBody.contact_info.contact_time.split(' - ')[0]) : ''}
                onChange={(e) => handleContactTimeChange('start', e.target.value)}
                className="me-2"
              />
              <Form.Control
                type="time"
                value={safeUpdateInfoBody?.contact_info?.contact_time ? convert12To24(safeUpdateInfoBody.contact_info.contact_time.split(' - ')[1]) : ''}
                onChange={(e) => handleContactTimeChange('end', e.target.value)}
              />
            </div>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Close</GoogleButton>
        <GoogleButton variant="primary" onClick={handleUpdateContactInfo} disabled={!isValid}>Update</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const HistoryModal = ({ show, onHide, selectedTax }) => {
    const combinedHistory = useMemo(() => {
      const stateEvents = selectedTax?.state_history?.map(change => ({
        ...change,
        type: 'Status Event',
        date: new Date(change.change_date),
        status: change.to_status.name
      })) || [];
  
      const contactEvents = selectedTax?.contact_history?.map(contact => ({
        ...contact,
        type: 'Contact Event',
        date: new Date(contact.change_date),
        status: contact.contact_status
      })) || [];
  
      return [...stateEvents, ...contactEvents].sort((a, b) => b.date - a.date);
    }, [selectedTax]);
  
    const getStatusColor = (status) => {
      if (typeof status === 'object' && status.color) {
        return status.color;
      }
      // Fallback colors for contact statuses or if color is not provided
      const colorMap = {
        NO_ANSWER: '#FFA500',
        // Add more contact status colors as needed
      };
      return colorMap[status] || '#808080'; // Default to gray if status is not found
    };
  
    return (
      <GoogleModal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Tax Order History</Modal.Title>
        </Modal.Header>
        <TimelineContainer>
          {combinedHistory.map((event, index) => (
            <TimelineItem key={index}>
              <TimelineIcon>
                {event.type === 'Status Event' ? (
                  <FaCircle color={getStatusColor(event.to_status)} />
                ) : (
                  <FaRegCircle color={getStatusColor(event.status)} />
                )}
              </TimelineIcon>
              <TimelineContent>
                <TimelineDate>{event.date.toLocaleString()}</TimelineDate>
                <TimelineTitle>{event.type}: {event.status}</TimelineTitle>
                <TimelineNote>
                  Note: {event.note || 'No note provided'}
                </TimelineNote>
                {event.type === 'Status Event' && (
                  <TimelineNote>
                    <strong>From:</strong> {event.from_status.name} <strong>To:</strong> {event.to_status.name}
                  </TimelineNote>
                )}
                {event.type === 'Contact Event' && (
                  <TimelineNote>
                    <strong>Contact Status:</strong> {event.contact_status}
                  </TimelineNote>
                )}
              </TimelineContent>
            </TimelineItem>
          ))}
        </TimelineContainer>
        <Modal.Footer>
          <GoogleButton variant="secondary" onClick={onHide}>Close</GoogleButton>
        </Modal.Footer>
      </GoogleModal>
    );
  };

export const AssignAgentModal = ({ show, onHide, agents, selectedAgent, setSelectedAgent, handleAssignAgent, selectedRowsCount }) => {
  const [notifyClient, setNotifyClient] = useState(false);

  const onAssign = () => {
    handleAssignAgent(notifyClient);
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Agent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ color: '#5f6368' }}>Selected rows: <strong>{selectedRowsCount}</strong></p>
        <GoogleForm>
          <Form.Group>
            <Form.Label>Select Agent</Form.Label>
            <Form.Select
              value={selectedAgent}
              onChange={(e) => setSelectedAgent(e.target.value)}
            >
              <option value="">Choose an agent</option>
              {agents.map((agent) => (
                <option key={agent.id} value={agent.id}>
                  {agent.name} ({agent.email})
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Check 
              type="checkbox"
              id="notifyClientCheck"
              label="Notify client"
              checked={notifyClient}
              onChange={(e) => setNotifyClient(e.target.checked)}
            />
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>
          Cancel
        </GoogleButton>
        <GoogleButton 
          variant="primary" 
          onClick={onAssign} 
          disabled={!selectedAgent}
        >
          Assign
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const NotifyCallModal = ({ show, onHide, selectedInsurance, handleNotifyCall }) => {
  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Call Notification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to send a call notification to {selectedInsurance?.first_name} {selectedInsurance?.last_name}?</p>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="primary" onClick={handleNotifyCall}>Send Notification</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const ContactStatusModal = ({ show, onHide, selectedInsurance, handleAddContactStatus }) => {
  const [note, setNote] = useState('');
  const [phoneStatus, setPhoneStatus] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(phoneStatus !== '');
  }, [phoneStatus]);

  const handleSubmit = () => {
    handleAddContactStatus({
      note,
      contact_status: phoneStatus
    });
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Call Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group className="mb-3">
            <Form.Label>Call Status</Form.Label>
            <Form.Select
              value={phoneStatus}
              onChange={(e) => setPhoneStatus(e.target.value)}
              isInvalid={!isValid}
            >
              <option value="">Select a status</option>
              <option value="NO_ANSWER">No Answer</option>
              <option value="PHONE_BUSY">Phone Busy</option>
              <option value="FORWARDED_TO_VOICEMAIL">Forwarded to Voicemail</option>
              <option value="NUMBER_OUT_OF_SERVICE">Number Out of Service</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select a call status.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Note</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="primary" onClick={handleSubmit} disabled={!isValid}>
          Update Status
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const AlertModal = ({ show, onHide, title, message, variant }) => {
  return (
    <StyledModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant={variant === 'success' ? 'primary' : 'secondary'} onClick={onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
};
// Incident.jsx
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Form, Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import styled, { createGlobalStyle } from 'styled-components';
import { FaFilter, FaPlus } from 'react-icons/fa';
import { fetchIncidents, fetchIncidentTypes } from './apiIncident';
import { IncidentSlidingPanel } from './IncidentSlidingPanel';
import { CreateEditModal } from './incidentModals';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: #f1f3f4;
    color: #202124;
  }
`;

const StyledDataTable = styled(DataTable)`
  .rdt_Table {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  .rdt_TableHead {
    font-weight: 500;
    color: #5f6368;
    background-color: #f1f3f4;
  }

  .rdt_TableCol {
    padding: 16px;
  }

  .rdt_TableCell {
    font-size: 14px;
  }

  .rdt_Pagination {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
  }
`;

const SlidingPanel = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-100%'};
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  transition: right 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 999;
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircularProgress = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const FilterDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    font-weight: 500;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
      box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    }
  }

  .dropdown-menu {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
    border: none;
    border-radius: 4px;
  }
`;

const AddButton = styled(Button)`
  margin-right: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ImageThumbnail = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 8px;
`;

const Incident = () => {
  const [incidents, setIncidents] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [types, setTypes] = useState([]);
  const [filters, setFilters] = useState({
    municipality: '',
    province: '',
    type: '',
    active: '',
    protestMagnitude: '',
    startDateFrom: '',
    startDateTo: '',
    endDateFrom: '',
    endDateTo: '',
    description: ''
  });

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        page: page.toString(),
        size: perPage.toString(),
        ...filters
      };

      const data = await fetchIncidents(params);
      setIncidents(data.content);
      setTotalRows(data.total_elements);
    } catch (error) {
      console.error('Error fetching incidents:', error);
    } finally {
      setLoading(false);
    }
  }, [page, perPage, filters]);

  const fetchTypes = async () => {
    try {
      const typesData = await fetchIncidentTypes();
      setTypes(typesData);
    } catch (error) {
      console.error('Error fetching types:', error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchTypes();
  }, [fetchData]);

  const handleFilterChange = (filterName, value) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
    setPage(0);
  };

  const clearFilters = () => {
    setFilters({
        municipality: '',
        province: '',
        type: '',
        active: '',
        protestMagnitude: '',
        startDateFrom: '',
        startDateTo: '',
        endDateFrom: '',
        endDateTo: '',
        description: ''
    });
    setPage(0);
  };

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handlePerRowsChange = async (newPerPage, newPage) => {
    setPerPage(newPerPage);
  };

  const handleRowClick = (row) => {
    setSelectedIncident(row);
    setIsPanelOpen(true);
  };

  const closeSlidingPanel = () => {
    setIsPanelOpen(false);
    setSelectedIncident(null);
  };

  const handleCreateSuccess = () => {
    setShowCreateModal(false);
    fetchData();
  };

  const columns = useMemo(() => [
    {
      name: 'Type',
      selector: row => row.type,
      sortable: true,
    },
    {
      name: 'Province',
      selector: row => row.province,
      sortable: true,
    },
    {
      name: 'Municipality',
      selector: row => row.municipality,
      sortable: true,
    },
    {
      name: 'Start Date',
      selector: row => new Date(row.start_date).toLocaleDateString(),
      sortable: true,
    },
    {
        name: 'End Date',
        selector: row => new Date(row.end_date).toLocaleDateString(),
        sortable: true,
      },
    {
      name: 'Status',
      selector: row => row.active ? 'Active' : 'Inactive',
      sortable: true,
    },
    {
      name: 'Magnitude',
      selector: row => row.protest_magnitude,
      sortable: true,
    },
    {
      name: 'Images',
      cell: row => row.images.slice(0, 3).map((image, index) => (
        <ImageThumbnail key={index} src={image} alt={`Image ${index + 1}`} />
      )),
    },
  ], []);

  return (
    <>
      <GlobalStyle />
      <div className="d-flex justify-content-end align-items-center mb-4">
        <AddButton variant="primary" onClick={() => setShowCreateModal(true)}>
          <FaPlus /> Add Incident
        </AddButton>
        <FilterDropdown>
          <Dropdown.Toggle variant="outline-secondary" id="dropdown-filters">
            <FaFilter /> Filters
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-3" style={{ width: '400px' }}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  value={filters.type}
                  onChange={(e) => handleFilterChange('type', e.target.value)}
                >
                  <option value="">All</option>
                  {types.map((type) => (
                    <option key={type.code} value={type.code}>
                      {type.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label>Province</Form.Label>
                <Form.Control
                  type="text"
                  value={filters.province}
                  onChange={(e) => handleFilterChange('province', e.target.value)}
                  placeholder="Enter province"
                />
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label>Municipality</Form.Label>
                <Form.Control
                  type="text"
                  value={filters.municipality}
                  onChange={(e) => handleFilterChange('municipality', e.target.value)}
                  placeholder="Enter municipality"
                />
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  value={filters.active}
                  onChange={(e) => handleFilterChange('active', e.target.value)}
                >
                  <option value="">All</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label>Protest Magnitude</Form.Label>
                <Form.Select
                  value={filters.protestMagnitude}
                  onChange={(e) => handleFilterChange('protestMagnitude', e.target.value)}
                >
                  <option value="">All</option>
                  <option value="LOW">Low</option>
                  <option value="MEDIUM">Medium</option>
                  <option value="HIGH">High</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  value={filters.description}
                  onChange={(e) => handleFilterChange('description', e.target.value)}
                  placeholder="Search in description"
                />
              </Form.Group>

              <div className="d-flex justify-content-end gap-2">
                <Button variant="secondary" onClick={clearFilters}>
                  Clear Filters
                </Button>
                <Button variant="primary" onClick={() => fetchData()}>
                  Apply Filters
                </Button>
              </div>
            </Form>
          </Dropdown.Menu>
        </FilterDropdown>
      </div>

      <StyledDataTable
        columns={columns}
        data={incidents}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={perPage}
        paginationDefaultPage={page + 1}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        progressPending={loading}
        progressComponent={
          <LoadingOverlay>
            <CircularProgress />
          </LoadingOverlay>
        }
        noDataComponent={
          <div style={{ padding: '24px', textAlign: 'center', color: '#5f6368' }}>
            No records available
          </div>
        }
        highlightOnHover
        pointerOnHover
        onRowClicked={handleRowClick}
      />

      <Overlay isOpen={isPanelOpen} onClick={closeSlidingPanel} />
      <SlidingPanel isOpen={isPanelOpen}>
        <IncidentSlidingPanel
          selectedIncident={selectedIncident}
          onClose={closeSlidingPanel}
          onUpdate={fetchData}
        />
      </SlidingPanel>

      <CreateEditModal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        onSuccess={handleCreateSuccess}
        types={types}
      />
    </>
  );
};

export default Incident;
import api from '../apiBase';

export const fetchIncidents = async (params) => {
  try {
    const response = await api.get('/api/incident-reports', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching incidents:', error);
    throw error;
  }
};

export const updateIncidentStatus = async (incidentId, status) => {
  try {
    const response = await api.put(
      `/api/incident-reports/${incidentId}/status?status=${status}`
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      throw new Error('Incident report not found');
    }
    console.error('Error updating incident status:', error);
    throw error;
  }
};

export const getProcessingStatuses = () => {
  // Estos son los estados definidos en el enum ProcessingStatus
  return [
    { code: 'NEW', name: 'New' },
    { code: 'PROCESSED', name: 'Processed' }
  ];
};
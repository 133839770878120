import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Container, Button, Form, Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FaFilter } from 'react-icons/fa';
import { getAllCookBooks, createCookBook, updateCookBook } from './apiCookBook';
import { CookBookModal } from './CookBookModals';
import { CookBookSlidingPanel } from './CookBookSlidingPanel';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: #f1f3f4;
    color: #202124;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
`;

const PageContainer = styled(Container)`
  width: 100%;
  max-width: none;
  padding: 24px;
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TableContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
`;

const StyledDataTable = styled(DataTable)`
  .rdt_Table {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  .rdt_TableHead {
    font-weight: 500;
    color: #5f6368;
    background-color: #f1f3f4;
  }

  .rdt_TableCol {
    padding: 16px;
  }

  .rdt_TableCell {
    padding: 16px;
    font-size: 14px;
  }

  .rdt_Pagination {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
  }
`;

const SlidingPanel = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-100%'};
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  transition: right 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 999;
`;

const GoogleButton = styled(Button)`
  background-color: #fff;
  border-color: #dadce0;
  color: #3c4043;
  font-weight: 500;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
    border-color: #dadce0;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &:disabled {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #80868b;
  }
`;

const FilterDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    font-weight: 500;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
      box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    }
  }

  .dropdown-menu {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
    border: none;
    border-radius: 4px;
  }
`;

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;  // Add vertical scroll
  padding: 0 16px;  // Add some padding
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
`;

const CircularProgress = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


const CookBookManager = () => {
    const [cookBooks, setCookBooks] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [filters, setFilters] = useState({title: '', description: ''});
    const [showAddModal, setShowAddModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
  
    const fetchCookBooksData = useCallback(async () => {
      setLoading(true);
      try {
        const data = await getAllCookBooks(page, perPage, filters);
        setCookBooks(data.content);
        setTotalRows(data.totalElements);
        setPage(data.number);
      } catch (error) {
        console.error('Error fetching cookbooks:', error);
      } finally {
        setLoading(false);
      }
    }, [page, perPage, filters]);
  
    useEffect(() => {
      fetchCookBooksData();
    }, [fetchCookBooksData]);
  
    const handleFilterChange = (filterName, value) => {
      setFilters(prevFilters => ({
        ...prevFilters,
        [filterName]: value
      }));
    };
  
    const handleAddCookBook = async (cookBookData, pictures) => {
      try {
        await createCookBook(cookBookData, pictures);
        fetchCookBooksData();
        setShowAddModal(false);
      } catch (error) {
        console.error('Error creating cookbook:', error);
      }
    };

    const handleUpdateCookBook = async (updatedCookBook, pictures) => {
        try {
          const result = await updateCookBook(updatedCookBook, pictures);
          setCookBooks(prevCookBooks =>
            prevCookBooks.map(cookBook =>
              cookBook.id === result.id ? result : cookBook
            )
          );
          setSelectedRow(result);
        } catch (error) {
          console.error('Error updating cookbook:', error);
        }
      };
  
    const clearFilters = () => {
      setFilters({
        title: '',
        description: '',
      });
      setPage(0);
    };
  
    const handleRowClick = (row) => {
      setSelectedRow(row);
      setIsPanelOpen(true);
    };
  
    const handlePageChange = (page) => {
      setPage(page - 1);
    };
  
    const handlePerRowsChange = async (newPerPage, newPage) => {
      setPerPage(newPerPage);
    };
  
    const closeSlidingPanel = () => {
      setIsPanelOpen(false);
      setSelectedRow(null);
    };
  
    const columns = useMemo(() => [
      {
        name: 'Image',
        cell: row => <img src={row.pictures[0]} alt={row.title} style={{ width: '50px', height: '25px', objectFit: 'cover' }} />,
      },
      {
        name: 'Title',
        selector: row => row.title,
        sortable: true,
        wrap: true,
      },
      {
        name: 'Description',
        selector: row => row.description,
        sortable: true,
        wrap: true,
      },
      {
        name: 'Ingredients',
        selector: row => row.ingredients.length,
        sortable: true,
      },
      {
        name: 'Steps',
        selector: row => row.steps.length,
        sortable: true,
      }
    ], []);
  
  return (
    <>
      <GlobalStyle />
      <PageContainer>
        <div className="d-flex justify-content-end gap-3 mb-4">
          <GoogleButton onClick={() => setShowAddModal(true)}>
            Add Cookbook
          </GoogleButton>
          <FilterDropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-filters">
              <FaFilter /> Filters
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-3" style={{ width: '300px' }}>
              <Form>
                <Form.Group className="mb-2">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    value={filters.title}
                    onChange={(e) => handleFilterChange('title', e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    value={filters.description}
                    onChange={(e) => handleFilterChange('description', e.target.value)}
                  />
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <GoogleButton onClick={clearFilters}>Clear Filters</GoogleButton>
                </div>
              </Form>
            </Dropdown.Menu>
          </FilterDropdown>
        </div>

        <TableContainer>
        <ScrollableContent>
            {loading && (
              <LoadingOverlay>
                <CircularProgress />
              </LoadingOverlay>
            )}
          <StyledDataTable
            columns={columns}
            data={cookBooks}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            paginationDefaultPage={page + 1}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            progressPending={loading}
            progressComponent={<div />}
            noDataComponent={<div style={{ padding: '24px', textAlign: 'center', color: '#5f6368' }}>No records available</div>}
            onRowClicked={handleRowClick}
          />
          </ScrollableContent>
        </TableContainer>
      </PageContainer>

      <Overlay isOpen={isPanelOpen} onClick={closeSlidingPanel} />
      <SlidingPanel isOpen={isPanelOpen}>
          <CookBookSlidingPanel
            selectedCookBook={selectedRow}
            onClose={closeSlidingPanel}
            onUpdate={handleUpdateCookBook}
          />
        </SlidingPanel>

      <CookBookModal
          show={showAddModal}
          onHide={() => setShowAddModal(false)}
          onSubmit={handleAddCookBook}
          isUpdating={false}
        />
    </>
  );
};

export default CookBookManager;
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Modal, Form, Button, ListGroup } from 'react-bootstrap';
import { FaTimes, FaPlus, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';

const GoogleModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.3), 0 4px 8px 3px rgba(60,64,67,0.15);
  }

  .modal-header {
    border-bottom: 1px solid #e8eaed;
    padding: 24px 24px 20px;
  }

  .modal-title {
    font-size: 22px;
    color: #202124;
    font-weight: 400;
  }

  .modal-body {
    padding: 24px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }

  .modal-footer {
    border-top: 1px solid #e8eaed;
    padding: 20px 24px 24px;
  }
`;

const GoogleForm = styled(Form)`
  .form-label {
    font-weight: 500;
    color: #5f6368;
    margin-bottom: 8px;
  }

  .form-control {
    border-radius: 4px;
    border: 1px solid #dadce0;
    padding: 8px 12px;
    font-size: 14px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
    }
  }
`;

const GoogleButton = styled(Button)`
  border-radius: 4px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 24px;

  &.btn-primary {
    background-color: #1a73e8;
    border-color: #1a73e8;
    color: #fff;
    
    &:hover, &:focus {
      background-color: #1765cc;
      border-color: #1765cc;
    }

    &:disabled {
      background-color: #8ab4f8;
      border-color: #8ab4f8;
    }
  }

  &.btn-secondary {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
    }
  }
`;

const DropzoneArea = styled.div`
  border: 2px dashed #dadce0;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    border-color: #1a73e8;
  }
`;

const ImagePreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ImagePreview = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

const RemoveImageButton = styled.button`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  color: #5f6368;

  &:hover {
    background-color: #f1f3f4;
  }
`;

const ListItem = styled(ListGroup.Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  margin-bottom: 8px;
`;

const AddButton = styled(GoogleButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    margin-right: 8px;
  }
`;

const ArrowButton = styled(Button)`
  padding: 0;
  font-size: 14px;
  line-height: 1;
  border: none;
  background: none;
  color: #5f6368;
  margin: 0 5px;

  &:hover, &:focus {
    color: #1a73e8;
  }

  &:disabled {
    color: #dadce0;
  }
`;

export const CookBookModal = ({ show, onHide, cookBook: initialCookBook, onSubmit, isUpdating }) => {
  const [cookBook, setCookBook] = useState(initialCookBook || {
    title: '',
    description: '',
    videoUrl: '',
    preparation_time: '',
    servings: '',
    category: '',
    ingredients: [],
    steps: [],
    pictures: [],
  });
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showIngredientModal, setShowIngredientModal] = useState(false);
  const [showStepModal, setShowStepModal] = useState(false);

  useEffect(() => {
    if (initialCookBook) {
      setCookBook(initialCookBook);
    }
  }, [initialCookBook]);

  const onDrop = useCallback((acceptedFiles) => {
    setCookBook(prevCookBook => ({
      ...prevCookBook,
      pictures: [
        ...prevCookBook.pictures,
        ...acceptedFiles.map(file => ({
          file,
          preview: URL.createObjectURL(file)
        }))
      ]
    }));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const resetForm = () => {
    setCookBook(initialCookBook || {
      title: '',
      description: '',
      videoUrl: '',
      preparation_time: '',
      category: '',
      servings: '',
      ingredients: [],
      steps: [],
      pictures: []
    });
    
    setIsValid(false);
    setIsLoading(false);
    setShowIngredientModal(false);
    setShowStepModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCookBook(prevCookBook => ({
      ...prevCookBook,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      
      const cookBookToSubmit = {
        ...cookBook,
        pictures: cookBook.pictures ? cookBook.pictures.map(pic => 
          pic.file || pic
        ) : []
      };
      if(!isUpdating){
        cookBookToSubmit.pictures = null
      }
      const picturesForSubmission = cookBook.pictures
      .filter(pic => pic.file)
      .map(pic => pic.file);
      await onSubmit(cookBookToSubmit, picturesForSubmission);
      onHide();
    } catch (error) {
      console.error('Error submitting cookbook:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsValid(
      cookBook.title.trim() !== '' &&
      cookBook.description.trim() !== '' &&
      cookBook.preparation_time !== '' &&
      cookBook.servings !== '' &&
      cookBook.category !== '' &&
      cookBook.ingredients.length > 0 &&
      cookBook.steps.length > 0
    );
  }, [cookBook]);

  useEffect(() => {
    return () => cookBook.pictures.forEach(pic => {
      if (pic.preview && pic.preview.startsWith('blob:')) {
        URL.revokeObjectURL(pic.preview);
      }
    });
  }, [cookBook.pictures]);

  const handleRemovePicture = (index) => {
    setCookBook(prevCookBook => ({
      ...prevCookBook,
      pictures: prevCookBook.pictures.filter((_, i) => i !== index)
    }));
  };

  const handleAddIngredient = (ingredient) => {
    setCookBook(prev => ({
      ...prev,
      ingredients: [...prev.ingredients, { ...ingredient, order: prev.ingredients.length + 1 }]
    }));
    setShowIngredientModal(false);
  };

  const handleAddStep = (step) => {
    setCookBook(prev => ({
      ...prev,
      steps: [...prev.steps, { ...step, order: prev.steps.length + 1 }]
    }));
    setShowStepModal(false);
  };

  const handleRemoveIngredient = (index) => {
    setCookBook(prev => ({
      ...prev,
      ingredients: prev.ingredients.filter((_, i) => i !== index)
    }));
  };

  const handleRemoveStep = (index) => {
    setCookBook(prev => ({
      ...prev,
      steps: prev.steps.filter((_, i) => i !== index)
    }));
  };

  const moveItem = (list, index, direction) => {
    const newList = [...list];
    const item = newList[index];
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    newList.splice(index, 1);
    newList.splice(newIndex, 0, item);
    return newList.map((item, index) => ({ ...item, order: index + 1 }));
  };

  const handleMoveIngredient = (index, direction) => {
    setCookBook(prev => ({
      ...prev,
      ingredients: moveItem(prev.ingredients, index, direction)
    }));
  };

  const handleMoveStep = (index, direction) => {
    setCookBook(prev => ({
      ...prev,
      steps: moveItem(prev.steps, index, direction)
    }));
  };

  return (
    <GoogleModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{isUpdating ? 'Update Cookbook' : 'Create New Cookbook'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <GoogleForm onSubmit={(e) => e.preventDefault()}>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={cookBook.title}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={cookBook.description}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <Form.Control
              type="text"
              name="category"
              value={cookBook.category}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Video URL</Form.Label>
            <Form.Control
              type="url"
              name="videoUrl"
              value={cookBook.videoUrl}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Preparation Time (minutes)</Form.Label>
            <Form.Control
              type="number"
              name="preparation_time"
              value={cookBook.preparation_time}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Servings</Form.Label>
            <Form.Control
              type="number"
              name="servings"
              value={cookBook.servings}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          
          <h5>Ingredients</h5>
          <ListGroup className="mb-3">
            {cookBook.ingredients.map((ingredient, index) => (
              <ListItem key={index}>
                <div>
                  {`${ingredient.quantity} ${ingredient.unit} ${ingredient.name}`}
                </div>
                <div>
                  <ArrowButton 
                    onClick={(e) => { e.stopPropagation(); handleMoveIngredient(index, 'up'); }}
                    disabled={index === 0}
                  >
                    <FaArrowUp />
                  </ArrowButton>
                  <ArrowButton 
                    onClick={(e) => { e.stopPropagation(); handleMoveIngredient(index, 'down'); }}
                    disabled={index === cookBook.ingredients.length - 1}
                  >
                    <FaArrowDown />
                  </ArrowButton>
                  <RemoveImageButton onClick={(e) => { e.stopPropagation(); handleRemoveIngredient(index); }}>
                    <FaTimes />
                  </RemoveImageButton>
                </div>
              </ListItem>
            ))}
          </ListGroup>
          <AddButton variant="secondary" onClick={(e) => { e.preventDefault(); setShowIngredientModal(true); }}>
            <FaPlus /> Add Ingredient
          </AddButton>

          <h5 className="mt-4">Steps</h5>
          <ListGroup className="mb-3">
            {cookBook.steps.map((step, index) => (
              <ListItem key={index}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {`Step ${index + 1}: ${step.description}`}
                </div>
                <div>
                  <ArrowButton 
                    onClick={(e) => { e.stopPropagation(); handleMoveStep(index, 'up'); }}
                    disabled={index === 0}
                  >
                    <FaArrowUp />
                  </ArrowButton>
                  <ArrowButton 
                    onClick={(e) => { e.stopPropagation(); handleMoveStep(index, 'down'); }}
                    disabled={index === cookBook.steps.length - 1}
                  >
                    <FaArrowDown />
                  </ArrowButton>
                  <RemoveImageButton onClick={(e) => { e.stopPropagation(); handleRemoveStep(index); }}>
                    <FaTimes />
                  </RemoveImageButton>
                </div>
              </ListItem>
            ))}
          </ListGroup>
          <AddButton variant="secondary" onClick={(e) => { e.preventDefault(); setShowStepModal(true); }}>
            <FaPlus /> Add Step
          </AddButton>

          <Form.Group className="mb-3 mt-4">
            <Form.Label>Cookbook Pictures</Form.Label>
            <DropzoneArea {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the images here ...</p>
              ) : (
                <p>Drag 'n' drop some images here, or click to select images</p>
              )}
            </DropzoneArea>
            <ImagePreviewContainer>
              {cookBook.pictures.map((pic, index) => (
                <ImagePreview key={index}>
                  <PreviewImage src={pic.preview || pic} alt={`preview ${index}`} />
                  <RemoveImageButton onClick={() => handleRemovePicture(index)}>
                    <FaTimes />
                  </RemoveImageButton>
                </ImagePreview>
              ))}
            </ImagePreviewContainer>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
      <GoogleButton variant="secondary" onClick={() => {
          resetForm();
          onHide();
        }}>
          Cancel
        </GoogleButton>
        <GoogleButton 
          variant="primary" 
          onClick={handleSubmit} 
          disabled={!isValid || isLoading}
        >
          {isLoading ? (isUpdating ? 'Updating...' : 'Creating...') : (isUpdating ? 'Update Cookbook' : 'Create Cookbook')}
        </GoogleButton>
      </Modal.Footer>

      <AddIngredientModal
        show={showIngredientModal}
        onHide={() => setShowIngredientModal(false)}
        onAdd={handleAddIngredient}
      />

      <AddStepModal
        show={showStepModal}
        onHide={() => setShowStepModal(false)}
        onAdd={handleAddStep}
      />
    </GoogleModal>
  );
};





const AddIngredientModal = ({ show, onHide, onAdd }) => {
  const [ingredient, setIngredient] = useState({ name: '', quantity: '', unit: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIngredient(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd(ingredient);
    setIngredient({ name: '', quantity: '', unit: '' });
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Ingredient</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={ingredient.name}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              type="text"
              name="quantity"
              value={ingredient.quantity}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Unit</Form.Label>
            <Form.Control
              type="text"
              name="unit"
              value={ingredient.unit}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="primary" onClick={handleSubmit}>Add Ingredient</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};




const AddStepModal = ({ show, onHide, onAdd }) => {
  const [step, setStep] = useState({ description: '', image: null });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStep(prev => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setStep(prev => ({ ...prev, image: e.target.files[0] }));}
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      onAdd(step);
      setStep({ description: '', image: null });
    };
  
    return (
      <GoogleModal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Add Step</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GoogleForm onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={step.description}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                onChange={handleImageChange}
                accept="image/*"
              />
            </Form.Group>
            {step.image && (
              <ImagePreview>
                <PreviewImage src={URL.createObjectURL(step.image)} alt="Step preview" />
                <RemoveImageButton onClick={() => setStep(prev => ({ ...prev, image: null }))}>
                  <FaTimes />
                </RemoveImageButton>
              </ImagePreview>
            )}
          </GoogleForm>
        </Modal.Body>
        <Modal.Footer>
          <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
          <GoogleButton variant="primary" onClick={handleSubmit}>Add Step</GoogleButton>
        </Modal.Footer>
      </GoogleModal>
    );
  };
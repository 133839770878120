import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Form, Button } from 'react-bootstrap';
import { FaCopy } from 'react-icons/fa';

const GoogleModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.3), 0 4px 8px 3px rgba(60,64,67,0.15);
  }

  .modal-header {
    border-bottom: 1px solid #e8eaed;
    padding: 24px 24px 20px;
  }

  .modal-title {
    font-size: 22px;
    color: #202124;
    font-weight: 400;
  }

  .modal-body {
    padding: 24px;
  }

  .modal-footer {
    border-top: 1px solid #e8eaed;
    padding: 20px 24px 24px;
  }
`;

const GoogleForm = styled(Form)`
  .form-label {
    font-weight: 500;
    color: #5f6368;
    margin-bottom: 8px;
  }

  .form-control {
    border-radius: 4px;
    border: 1px solid #dadce0;
    padding: 8px 12px;
    font-size: 14px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
    }
  }
`;

const GoogleButton = styled(Button)`
  background-color: #1a73e8;
  border-color: #1a73e8;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 8px 24px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #1765cc;
    border-color: #1765cc;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }
`;

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-top: 16px;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
`;

const CopyButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px 8px;
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  
  &:hover {
    background-color: #fff;
  }
`;

export const StatusModal = ({ 
  show, 
  onHide, 
  selectedIncident, 
  handleStatusChange,
  availableStatuses 
}) => {
  const [newStatus, setNewStatus] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(newStatus !== '');
    // Si hay un incidente seleccionado, establecer su estado actual como valor inicial
    if (selectedIncident && !newStatus) {
      setNewStatus(selectedIncident.status);
    }
  }, [newStatus, selectedIncident]);

  const handleSubmit = () => {
    // Enviamos directamente el código del estado (NEW o PROCESSED)
    handleStatusChange(newStatus);
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Change Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group>
            <Form.Label>Current Status: {selectedIncident?.status}</Form.Label>
            <Form.Select
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              isInvalid={!isValid}
            >
              <option value="">Select status</option>
              {availableStatuses.map((status) => (
                <option 
                  key={status.code} 
                  value={status.code}
                  disabled={status.code === selectedIncident?.status}
                >
                  {status.name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select a status
            </Form.Control.Feedback>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <GoogleButton 
          onClick={handleSubmit} 
          disabled={!isValid || newStatus === selectedIncident?.status}
        >
          Update Status
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const DetailsModal = ({ show, onHide, incident }) => {
  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url);
  };

  if (!incident) return null;

  return (
    <GoogleModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Incident Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group className="mb-3">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="text"
              value={incident.date}
              readOnly
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Time</Form.Label>
            <Form.Control
              type="text"
              value={incident.time}
              readOnly
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Municipality</Form.Label>
            <Form.Control
              type="text"
              value={incident.municipality}
              readOnly
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Province</Form.Label>
            <Form.Control
              type="text"
              value={incident.province}
              readOnly
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={incident.description || ''}
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Images</Form.Label>
            <ImageContainer>
              {incident.images.map((image, index) => (
                <ImageWrapper key={index}>
                  <Image src={image} alt={`Incident image ${index + 1}`} />
                  <CopyButton onClick={() => handleCopyLink(image)}>
                    <FaCopy /> Copy Link
                  </CopyButton>
                </ImageWrapper>
              ))}
            </ImageContainer>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Close</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { FaEdit, FaInfoCircle } from 'react-icons/fa';

const Panel = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FixedHeader = styled.div`
  padding: 24px 24px 0;
  background-color: #fff;
  border-bottom: 1px solid #dadce0;
`;

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 24px;
`;

const FixedActions = styled.div`
  padding: 24px;
  background-color: #fff;
  border-top: 1px solid #dadce0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Name = styled.h2`
  margin: 0;
  font-size: 22px;
  font-weight: 400;
  color: #202124;
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: #5f6368;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
`;

const InfoItem = styled.div`
  background-color: #f1f3f4;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  word-wrap: break-word;
  overflow-wrap: break-word;

  &:hover {
    background-color: #e8f0fe;
  }
`;

const InfoLabel = styled.div`
  font-size: 12px;
  color: #5f6368;
  margin-bottom: 4px;
`;

const InfoValue = styled.div`
  font-size: 14px;
  color: #202124;
  font-weight: 500;
  white-space: pre-wrap;
`;

const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
  background-color: #fff;
  border-color: #dadce0;
  color: #1a73e8;
  font-weight: 500;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #174ea6;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  svg {
    margin-right: 8px;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
  margin-top: 12px;
`;

const Image = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
`;

const InfoItemWithFallback = ({ label, value }) => (
  <InfoItem>
    <InfoLabel>{label}</InfoLabel>
    <InfoValue>{value || 'No data'}</InfoValue>
  </InfoItem>
);

export const IncidentSlidingPanel = ({ 
  selectedIncident,
  onOpenStatusModal,
  onOpenDetailsModal,
  onClose
}) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  if (!selectedIncident) return null;

  return (
    <Panel>
      <FixedHeader>
        <Header>
          <Name>Incident Report</Name>
        </Header>
      </FixedHeader>

      <ScrollableContent>
        <Section>
          <SectionTitle>Basic Information</SectionTitle>
          <InfoGrid>
            <InfoItemWithFallback label="Date" value={selectedIncident.date} />
            <InfoItemWithFallback label="Time" value={selectedIncident.time} />
            <InfoItemWithFallback label="Municipality" value={selectedIncident.municipality} />
            <InfoItemWithFallback label="Province" value={selectedIncident.province} />
            <InfoItemWithFallback label="Status" value={selectedIncident.status} />
          </InfoGrid>
        </Section>

        <Section>
          <SectionTitle>Description</SectionTitle>
          <InfoItem>
            <InfoValue>{selectedIncident.description || 'No description provided'}</InfoValue>
          </InfoItem>
        </Section>

        <Section>
          <SectionTitle>Images</SectionTitle>
          <ImageGrid>
            {selectedIncident.images.map((image, index) => (
              <Image key={index} src={image} alt={`Incident image ${index + 1}`} />
            ))}
          </ImageGrid>
        </Section>
      </ScrollableContent>

      <FixedActions>
        <Section>
          <SectionTitle>Actions</SectionTitle>
          <ActionButton onClick={() => onOpenStatusModal(selectedIncident)}>
            <FaEdit /> Change Status
          </ActionButton>
          <ActionButton onClick={() => onOpenDetailsModal(selectedIncident)}>
            <FaInfoCircle /> View Details
          </ActionButton>
        </Section>
      </FixedActions>
    </Panel>
  );
};
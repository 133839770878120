import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container, Button, Dropdown, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { FaEdit, FaTrash, FaFilter, FaImage } from 'react-icons/fa';
import { fetchBillboards, createBillboard, updateBillboard, deleteBillboard, uploadBillboardImage } from './apiBillboard';
import { AddBillboardModal, EditBillboardModal, DeleteConfirmationModal, UpdateBillboardImageModal } from '../customViews/modals';

const PageContainer = styled(Container)`
  width: 100%;
  max-width: none;
  padding: 24px;
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
`;

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;  // Add vertical scroll
  padding: 0 16px;  // Add some padding
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
`;

const CircularProgress = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const StyledDataTable = styled(DataTable)`
  .rdt_TableHead {
    font-weight: 500;
    color: #5f6368;
    background-color: #f1f3f4;
  }

  .rdt_TableCell {
    font-size: 14px;
  }
`;

const GoogleButton = styled(Button)`
  background-color: #fff;
  border-color: #dadce0;
  color: #3c4043;
  font-weight: 500;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
    border-color: #dadce0;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }
`;

const FilterDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    font-weight: 500;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
      box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    }
  }

  .dropdown-menu {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
    border: none;
    border-radius: 4px;
  }
`;

const BillboardManager = () => {
    const [billboards, setBillboards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedBillboard, setSelectedBillboard] = useState(null);
    const [showImageUpdateModal, setShowImageUpdateModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [filters, setFilters] = useState({
      company: '',
      active: '',
    });
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
  
    const fetchBillboardsData = useCallback(async () => {
      setLoading(true);
      try {
        const response = await fetchBillboards({
          ...filters,
          page,
          size: perPage,
        });
        setBillboards(response.content);
        setTotalRows(response.total_elements);
      } catch (error) {
        console.error('Error fetching billboards:', error);
        setBillboards([]);
      } finally {
        setLoading(false);
      }
    }, [filters, page, perPage]);
  
    useEffect(() => {
      fetchBillboardsData();
    }, [fetchBillboardsData]);
  
    const handleCreateBillboard = () => {
      setSelectedBillboard(null);
      setShowCreateModal(true);
    };
  
    const handleEditBillboard = (billboard) => {
      setSelectedBillboard(billboard);
      setShowEditModal(true);
    };
  
    const handleDeleteBillboard = (billboard) => {
      setSelectedBillboard(billboard);
      setShowDeleteModal(true);
    };
  
    const handleUpdateImage = (billboard) => {
        setSelectedBillboard(billboard);
        setShowImageUpdateModal(true);
      };
    
      const handleImageUpdateSubmit = async (imageFile) => {
        try {
          await uploadBillboardImage(selectedBillboard.id, imageFile);
          fetchBillboardsData();
          setShowImageUpdateModal(false);
        } catch (error) {
          console.error('Error updating billboard image:', error);
        }
      };
  
    const handleDeleteConfirm = async () => {
      try {
        await deleteBillboard(selectedBillboard.id);
        fetchBillboardsData();
      } catch (error) {
        console.error('Error deleting billboard:', error);
      } finally {
        setShowDeleteModal(false);
      }
    };

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowImageModal(true);
    };
  
    const handleFilterChange = (filterName, value) => {
      setFilters(prevFilters => ({
        ...prevFilters,
        [filterName]: value
      }));
      setPage(0);
    };
  
    const clearFilters = () => {
      setFilters({
        company: '',
        active: '',
      });
      setPage(0);
    };
  
    const handlePageChange = (page) => {
      setPage(page - 1);
    };
  
    const handlePerRowsChange = async (newPerPage, page) => {
      setPerPage(newPerPage);
      setPage(page - 1);
    };
  
    const columns = useMemo(() => [
        {
            name: 'Image',
            cell: row => (
              <div>
                <img 
                  src={row.image} 
                  alt="Billboard" 
                  style={{ width: '150px', height: '50px', objectFit: 'cover', cursor: 'pointer' }} 
                  onClick={() => handleImageClick(row.image)}
                />
                <GoogleButton onClick={() => handleUpdateImage(row)} className="ms-2">
                  <FaImage />
                </GoogleButton>
              </div>
            ),
        },
        {
            name: 'Company',
            selector: row => row.company,
            sortable: true,
        },
        {
            name: 'Active',
            selector: row => row.active ? 'Yes' : 'No',
            sortable: true,
        },
        {
            name: 'Renewal Date',
            selector: row => {
                const date = new Date(row.renewal_date);
                return date instanceof Date && !isNaN(date) ? date.toLocaleDateString() : 'N/A';
            },
            sortable: true,
        },
        {
            name: 'isGlobal',
            selector: row => row.is_global ? 'Yes' : 'No',
            sortable: true,
        },
        {
            name: 'countries',
            selector: row => row.countries,
            sortable: true,
        },
        {
            name: 'cities',
            selector: row => row.cities,
            sortable: true,
        },
        {
            name: 'states',
            selector: row => row.states,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
              <>
                <GoogleButton onClick={() => handleEditBillboard(row)} className="me-2">
                  <FaEdit />
                </GoogleButton>
                <GoogleButton onClick={() => handleDeleteBillboard(row)} variant="danger">
                  <FaTrash />
                </GoogleButton>
              </>
            ),
        },
    ], []);

    const conditionalRowStyles = [
        {
            when: row => {
                const renewalDate = new Date(row.renewal_date);
                const today = new Date();
                return renewalDate < today;
            },
            style: {
                backgroundColor: '#FFF0F0',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ];
  
    return (
      <PageContainer>
        <div className="d-flex justify-content-end gap-3 mb-4">
        <GoogleButton onClick={handleCreateBillboard}>
            Create Billboard
          </GoogleButton>
          <FilterDropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-filters">
              <FaFilter /> Filters
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-3" style={{ width: '300px' }}>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    type="text"
                    value={filters.company}
                    onChange={(e) => handleFilterChange('company', e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Active</Form.Label>
                  <Form.Select
                    value={filters.active}
                    onChange={(e) => handleFilterChange('active', e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Form.Select>
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <GoogleButton onClick={clearFilters}>Clear Filters</GoogleButton>
                </div>
              </Form>
            </Dropdown.Menu>
          </FilterDropdown>
        </div>
  
        <TableContainer>
        <ScrollableContent>
            {loading && (
              <LoadingOverlay>
                <CircularProgress />
              </LoadingOverlay>
            )}
              <StyledDataTable
                  columns={columns}
                  data={billboards}
                  progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  progressComponent={<div />}
                  conditionalRowStyles={conditionalRowStyles}
              />
                </ScrollableContent>
            </TableContainer>
  
        <AddBillboardModal
            show={showCreateModal}
            onHide={() => setShowCreateModal(false)}
            createBillboard={createBillboard}
            billboard={selectedBillboard}
            onSubmitSuccess={fetchBillboardsData}
        />

        <EditBillboardModal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            updateBillboard={updateBillboard}
            billboard={selectedBillboard}
            onSubmitSuccess={fetchBillboardsData}
        />

      <DeleteConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteConfirm}
        billboardName={selectedBillboard?.company}
      />

      <UpdateBillboardImageModal
        show={showImageUpdateModal}
        onHide={() => setShowImageUpdateModal(false)}
        onSubmit={handleImageUpdateSubmit}
        billboardName={selectedBillboard?.company}
      />

    <Modal show={showImageModal} onHide={() => setShowImageModal(false)} size="lg" centered>
        <Modal.Header closeButton>
            <Modal.Title>Billboard Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <img src={selectedImage} alt="Billboard" style={{ width: '100%', height: 'auto' }} />
        </Modal.Body>
    </Modal>
      </PageContainer>
    );
  };
  
  export default BillboardManager;
import api from '../apiBase';

export const fetchIncidentPeople = async (params) => {
  try {
    const response = await api.get('/api/incident-people', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching incident people:', error);
    throw error;
  }
};

export const createIncidentPeople = async (data) => {
  try {
    const response = await api.post('/api/incident-people', data);
    return response.data;
  } catch (error) {
    console.error('Error creating incident people:', error);
    throw error;
  }
};

export const updateIncidentPeople = async (id, data) => {
  try {
    const response = await api.put(`/api/incident-people/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating incident people:', error);
    throw error;
  }
};

export const updateIncidentPeopleType = async (id, type) => {
  try {
    const response = await api.put(`/api/incident-people/${id}/type`, { type });
    return response.data;
  } catch (error) {
    console.error('Error updating incident people type:', error);
    throw error;
  }
};

export const getIncidentPeopleTypes = async () => {
  try {
    const response = await api.get('/api/incident-people/types');
    return response.data;
  } catch (error) {
    console.error('Error fetching incident people types:', error);
    throw error;
  }
};

export const fetchProvinces = async () => {
  try {
    const response = await api.get('/api/locations/v1/provinces');
    return response.data;
  } catch (error) {
    console.error('Error fetching provinces:', error);
    throw error;
  }
};

export const fetchMunicipalities = async () => {
  try {
    const response = await api.get('/api/locations/v1/municipalities');
    return response.data;
  } catch (error) {
    console.error('Error fetching municipalities:', error);
    throw error;
  }
};

export const fetchIncidentActivities = async (parentId) => {
  try {
    const response = await api.get(`/api/incident-activities/parent/${parentId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching incident activities:', error);
    throw error;
  }
};

export const createIncidentActivity = async (data) => {
  try {
    const response = await api.post('/api/incident-activities', data);
    return response.data;
  } catch (error) {
    console.error('Error creating incident activity:', error);
    throw error;
  }
};

export const updateIncidentActivity = async (id, data) => {
  try {
    const response = await api.put(`/api/incident-activities/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating incident activity:', error);
    throw error;
  }
};

export const deleteIncidentActivity = async (id) => {
  try {
    await api.delete(`/api/incident-activities/${id}`);
  } catch (error) {
    console.error('Error deleting incident activity:', error);
    throw error;
  }
};

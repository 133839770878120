import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Button, Form, Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import styled, { createGlobalStyle } from 'styled-components';
import { 
  NotesModal, 
  EditNoteModal, 
  StatusModal, 
  ContactModal, 
  NotifyCallModal,
  ContactStatusModal,
  AlertModal,
  HistoryModal  } from './taxesModal';
import { TaxesSlidingPanel } from './TaxesSlidingPanel';
import { FaFilter } from 'react-icons/fa';
import { sendNotificationToUser } from '../admin/apiAdmin';
import {
  fetchTaxes,
  addNote,
  editNote,
  deleteNote,
  changeStatus,
  getAvailableStatuses,
  updateContactInfo,
  getStatuses,
  addContactStatus
} from './apiTaxes';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: #f1f3f4;
    color: #202124;
  }
`;

const StyledDataTable = styled(DataTable)`
  .rdt_Table {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  .rdt_TableHead {
    font-weight: 500;
    color: #5f6368;
    background-color: #f1f3f4;
  }

  .rdt_TableCol {
    padding: 16px;
  }

  .rdt_TableCell {
    font-size: 14px;
  }

  .rdt_Pagination {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
  }
`;

const SlidingPanel = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-100%'};
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  transition: right 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 999;
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircularProgress = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const GoogleButton = styled(Button)`
  background-color: #1a73e8;
  border-color: #1a73e8;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 8px 24px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #1765cc;
    border-color: #1765cc;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }
`;

const FilterDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    font-weight: 500;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
      box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    }
  }

  .dropdown-menu {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
    border: none;
    border-radius: 4px;
  }
`;

const TaxesAgent = () => {
  const [taxes, setTaxes] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedTax, setSelectedTax] = useState(null);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [editingNote, setEditingNote] = useState({ index: null, text: '' });
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const [newStatus, setNewStatus] = useState('');
  const [statusNote, setStatusNote] = useState('');
  const [updateInfoBody, setUpdateInfoBody] = useState({});
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const panelRef = useRef(null);
  const [showNotifyCallModal, setShowNotifyCallModal] = useState(false);
  const [showContactStatusModal, setShowContactStatusModal] = useState(false);
  const [alertModal, setAlertModal] = useState({ show: false, title: '', message: '', variant: 'success' });

  const [filters, setFilters] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    status: '',
    agent_name: '',
    no_agent: false
  });

  const fetchTaxesData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        page: page.toString(),
        size: perPage.toString(),
        ...filters
      };

      const data = await fetchTaxes(params);
      setTaxes(data.content);
      setTotalRows(data.total_elements);
      setPage(data.number);
    } catch (error) {
      console.error('Error fetching taxes:', error);
    } finally {
      setLoading(false);
    }
  }, [page, perPage, filters]);

  useEffect(() => {
    fetchTaxesData();
  }, [page, perPage, filters, fetchTaxesData]);

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const statuses = await getStatuses();
        setAvailableStatuses(statuses);
      } catch (error) {
        console.error('Error fetching available statuses:', error);
      }
    };
    fetchStatuses();
  }, []);

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const clearFilters = () => {
    setFilters({
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      status: '',
      agent_name: '',
      no_agent: false
    });
    setPage(0);
  };

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handlePerRowsChange = async (newPerPage, newPage) => {
    setPerPage(newPerPage);
  };

  const openNotesModal = (tax) => {
    setSelectedTax(tax);
    setShowNotesModal(true);
  };

  const closeNotesModal = () => {
    setShowNotesModal(false);
    setSelectedTax(null);
    setNewNote('');
  };

  const handleAddNote = async () => {
    try {
      await addNote(selectedTax.id, newNote);
      fetchTaxesData();
      closeNotesModal();
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const openEditNoteModal = (noteIndex, noteText) => {
    setEditingNote({ index: noteIndex, text: noteText });
    setShowEditNoteModal(true);
    setShowNotesModal(false);
  };

  const closeEditNoteModal = () => {
    setShowEditNoteModal(false);
    setEditingNote({ index: null, text: '' });
    setShowNotesModal(true);
  };

  const handleEditNote = async () => {
    try {
      await editNote(selectedTax.id, editingNote.index, editingNote.text);
      fetchTaxesData();
      closeEditNoteModal();
    } catch (error) {
      console.error('Error editing note:', error);
    }
  };

  const handleDeleteNote = async (noteIndex) => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      try {
        await deleteNote(selectedTax.id, noteIndex);
        fetchTaxesData();
      } catch (error) {
        console.error('Error deleting note:', error);
        alert('Failed to delete note');
      }
    }
  };

  const handleOpenStatusModal = async (tax) => {
    setSelectedTax(tax);
    try {
      const statuses = await getAvailableStatuses(tax.id);
      setAvailableStatuses(statuses);
      setShowStatusModal(true);
    } catch (error) {
      console.error('Error fetching available statuses:', error);
    }
  };

  const closeStatusModal = () => {
    setShowStatusModal(false);
    setSelectedTax(null);
    setNewStatus('');
    setStatusNote('');
  };

  const handleChangeStatus = async () => {
    try {
      await changeStatus(selectedTax.id, newStatus, statusNote);
      fetchTaxesData();
      closeStatusModal();
    } catch (error) {
      console.error('Error changing status:', error);
    }
  };

  const openContactModal = (taxes) => {
    setSelectedTax(taxes);
    setUpdateInfoBody({
      contact_info: { ...taxes.contact_info },
      address: { ...taxes.address }
    });
    setShowContactModal(true);
  };

  const closeContactModal = () => {
    setShowContactModal(false);
    setSelectedTax(null);
    setUpdateInfoBody({});
  };

  const handleUpdateContactInfo = async () => {
    try {
      await updateContactInfo(selectedTax.id, updateInfoBody);
      fetchTaxesData();
      closeContactModal();
    } catch (error) {
      console.error('Error updating contact info:', error);
    }
  };

  const openHistoryModal = (tax) => {
    setSelectedTax(tax);
    setShowHistoryModal(true);
  };

  const closeHistoryModal = () => {
    setShowHistoryModal(false);
    setSelectedTax(null);
  };

  const handleRowClick = (row) => {
    if (!isPanelOpen) {
      setSelectedRow(row);
      setIsPanelOpen(true);
    }
  };

  const closeSlidingPanel = () => {
    setIsPanelOpen(false);
    setSelectedRow(null);
  };

  const handleOutsideClick = (event) => {
    if (panelRef.current && !panelRef.current.contains(event.target)) {
      closeSlidingPanel();
    }
  };

  const formatDateToInput = (date) => {
    if (!date) return '';
    const [month, day, year] = date.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };
  
  const formatDateToOutput = (date) => {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    return `${parseInt(month)}/${parseInt(day)}/${year}`;
  };

  const openNotifyCallModal = useCallback((tax) => {
    setSelectedTax(tax);
    setShowNotifyCallModal(true);
  }, []);

  const closeNotifyCallModal = useCallback(() => {
    setShowNotifyCallModal(false);
    setSelectedTax(null);
  }, []);

  const showAlert = useCallback((title, message, variant = 'success') => {
    setAlertModal({ show: true, title, message, variant });
  }, []);

  const closeAlert = useCallback(() => {
    setAlertModal({ show: false, title: '', message: '', variant: 'success' });
  }, []);

  const handleNotifyCall = useCallback(async () => {
    try {
      await sendNotificationToUser(
        selectedTax.user_id,
        selectedTax.id,
      );
      closeNotifyCallModal();
    } catch (error) {
      console.error('Error al enviar la notificación:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Error desconocido al enviar la notificación';
      showAlert('Error', errorMessage, 'error');
    } finally {
      closeNotifyCallModal();
    }
  }, [selectedTax, showAlert, closeNotifyCallModal]);

  const openContactStatusModal = useCallback((tax) => {
    setSelectedTax(tax);
    setShowContactStatusModal(true);
  }, []);

  const closeContactStatusModal = useCallback(() => {
    setShowContactStatusModal(false);
    setSelectedTax(null);
  }, []);

  const handleAddContactStatus = useCallback(async (newContactStatus) => {
    try {
      await addContactStatus(selectedTax.id, newContactStatus);
      closeContactStatusModal();
      fetchTaxesData();
    } catch (error) {
      console.error('Error adding contact status:', error);
    }
  }, [selectedTax, closeContactStatusModal, fetchTaxesData]);

  const columns = useMemo(() => [
    {
      name: 'Name',
      selector: row => `${row.contact_info.first_name} ${row.contact_info.last_name}`,
      sortable: true,
      wrap: true,
      autoWidth: true,
    },
    {
      name: 'Email',
      selector: row => row.contact_info.email,
      sortable: true,
      wrap: true,
      autoWidth: true,
    },
    {
      name: 'Phone Number',
      selector: row => row.contact_info.phone_number,
      sortable: true,
      wrap: true,
      autoWidth: true,
    },
    {
      name: 'Address',
      selector: row => row.address.address,
      sortable: true,
      wrap: true,
      autoWidth: true,
    },
    {
      name: 'Postal Code',
      selector: row => row.address.postal_code,
      sortable: true,
      wrap: true,
      autoWidth: true,
    },
    {
      name: 'Status',
      selector: row => row.status.name,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Time Available For Contact',
      selector: row => row.contact_info.contact_time,
      sortable: true,
      wrap: true,
      autoWidth: true,
    },
    {
      name: 'Days Available For Contact',
      selector: row => row.contact_info.contact_days,
      sortable: true,
      wrap: true,
      autoWidth: true,
    },
    {
      name: 'Call Attempt',
      selector: row => row.contact_history ? row.contact_history.length : '0',
      sortable: true,
      wrap: true,
    }
  ], []);
  
  return (
    <>
      <GlobalStyle />
        <div className="d-flex justify-content-end align-items-center mb-4">
          <div className="d-flex">
            <FilterDropdown>
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-filters">
                <FaFilter /> Filters
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-3" style={{ width: '300px' }}>
                <Form>
                  <Form.Group className="mb-2">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={filters.first_name}
                      onChange={(e) => handleFilterChange('first_name', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={filters.last_name}
                      onChange={(e) => handleFilterChange('last_name', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      value={filters.email}
                      onChange={(e) => handleFilterChange('email', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      value={filters.phone_number}
                      onChange={(e) => handleFilterChange('phone_number', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={filters.status}
                    onChange={(e) => handleFilterChange('status', e.target.value)}
                  >
                    <option value="">All</option>
                    {availableStatuses.map((status) => (
                      <option key={status.code} value={status.code}>
                        {status.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                  <div className="d-flex justify-content-end">
                    <GoogleButton onClick={clearFilters}>Clear Filters</GoogleButton>
                  </div>
                </Form>
              </Dropdown.Menu>
            </FilterDropdown>
          </div>
        </div>
            <StyledDataTable
              columns={columns}
              data={taxes}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={perPage}
              paginationDefaultPage={page + 1}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              noDataComponent={<div style={{ padding: '24px', textAlign: 'center', color: '#5f6368' }}>No records available</div>}
              progressPending={loading}
              progressComponent={
                <LoadingOverlay>
                  <CircularProgress />
                </LoadingOverlay>
              }
              fixedHeader
              paginationComponentOptions={{
                rowsPerPageText: 'Rows per page:',
                rangeSeparatorText: 'of',
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: 'All'
              }}
              selectableRows={false}
              highlightOnHover
              pointerOnHover
              onRowClicked={handleRowClick}
            />

      <Overlay isOpen={isPanelOpen} onClick={handleOutsideClick} />
      <SlidingPanel isOpen={isPanelOpen} ref={panelRef}>
        <TaxesSlidingPanel
          selectedRow={selectedRow}
          openNotesModal={openNotesModal}
          handleOpenStatusModal={handleOpenStatusModal}
          openContactModal={openContactModal}
          openHistoryModal={openHistoryModal}
          openNotifyCallModal={openNotifyCallModal}
          openContactStatusModal={openContactStatusModal}
          onClose={closeSlidingPanel}
        />
      </SlidingPanel>

      <NotesModal 
        show={showNotesModal}
        onHide={closeNotesModal}
        selectedInsurance={selectedRow}
        newNote={newNote}
        setNewNote={setNewNote}
        handleAddNote={handleAddNote}
        openEditNoteModal={openEditNoteModal}
        handleDeleteNote={handleDeleteNote}
      />

      <EditNoteModal 
        show={showEditNoteModal}
        onHide={closeEditNoteModal}
        editingNote={editingNote}
        setEditingNote={setEditingNote}
        handleEditNote={handleEditNote}
      />

      <StatusModal 
        show={showStatusModal}
        onHide={closeStatusModal}
        newStatus={newStatus}
        setNewStatus={setNewStatus}
        statusNote={statusNote}
        setStatusNote={setStatusNote}
        availableStatuses={availableStatuses}
        handleChangeStatus={handleChangeStatus}
      />

      <ContactModal 
        show={showContactModal}
        onHide={closeContactModal}
        updateInfoBody={updateInfoBody}
        setUpdateInfoBody={setUpdateInfoBody}
        handleUpdateContactInfo={handleUpdateContactInfo}
        formatDateToInput={formatDateToInput}
        formatDateToOutput={formatDateToOutput}
      />

      <HistoryModal 
        show={showHistoryModal}
        onHide={closeHistoryModal}
        selectedTax={selectedTax}
      />

      <NotifyCallModal 
        show={showNotifyCallModal}
        onHide={closeNotifyCallModal}
        selectedTax={selectedTax}
        handleNotifyCall={handleNotifyCall}
      />

      <ContactStatusModal 
        show={showContactStatusModal}
        onHide={closeContactStatusModal}
        selectedTax={selectedTax}
        handleAddContactStatus={handleAddContactStatus}
      />

      <AlertModal
        show={alertModal.show}
        onHide={closeAlert}
        title={alertModal.title}
        message={alertModal.message}
        variant={alertModal.variant}
      />
    </>
  );
};

export default TaxesAgent;
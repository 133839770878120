import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getExtendedOrderStatistics } from './apiNotary';
import ModernPieChart from '../customViews/ModernPieChart';

const PageContainer = styled(Container)`
  width: 100%;
  max-width: none;
  padding: 24px;
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f1f3f4;
  overflow-y: auto;  // Add vertical scroll
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  padding: 24px;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  color: #202124;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 16px;
`;

const StatCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  padding: 16px;
  text-align: center;
  height: 100%; // Ensure all cards have the same height
`;

const StatValue = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #1a73e8;
`;

const StatLabel = styled.div`
  font-size: 14px;
  color: #5f6368;
  margin-top: 8px;
`;

const DateRangeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 130px;
  }

  .react-datepicker__input-container input {
    width: 100%;
    padding: 8px;
    border: 1px solid #dadce0;
    border-radius: 4px;
    font-size: 14px;
    color: #3c4043;
  }
`;

const DateRangeSeparator = styled.span`
  margin: 0 16px;
  color: #5f6368;
`;

const PresetButton = styled.button`
  background-color: ${props => props.active ? '#e8f0fe' : '#fff'};
  color: #1a73e8;
  border: 1px solid #dadce0;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  margin-right: 8px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e8f0fe;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
`;

const CircularProgress = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const TableContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;


const NotaryStatistics = () => {
    const [startDate, setStartDate] = useState(new Date(new Date().setUTCHours(0,0,0,0)));
    const [endDate, setEndDate] = useState(new Date(new Date().setUTCHours(23,59,59,999)));
    const [statistics, setStatistics] = useState(null);
    const [activePreset, setActivePreset] = useState('today');
    const [loading, setLoading] = useState(false);

    const sortedServiceData = useMemo(() => {
      if (!statistics || !statistics.service_count_map) return [];
      return Object.entries(statistics.service_count_map)
          .sort(([, a], [, b]) => b.totalCount - a.totalCount)
          .map(([service, stats]) => ({ service, ...stats }));
  }, [statistics]);

  const serviceOrdersAndCostData = useMemo(() => {
    if (!statistics || !statistics.service_count_map) return [];
    return Object.entries(statistics.service_count_map)
        .map(([service, stats]) => ({
            service,
            totalOrders: stats.total_count || 0,
            totalCost: stats.total_cost || 0
        }))
        .sort((a, b) => b.totalOrders - a.totalOrders);
}, [statistics]);

  const NotaryOrderStatus = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    FINISHED: 'FINISHED',
    CANCELLED: 'CANCELLED'
};

const fetchStatistics = useCallback(async () => {
  setLoading(true);
  try {
    const response = await getExtendedOrderStatistics(startDate.toISOString(), endDate.toISOString());
    setStatistics(response.data);
  } catch (error) {
    console.error('Error fetching statistics:', error);
  } finally {
    setLoading(false);
  }
}, [startDate, endDate]);

useEffect(() => {
  fetchStatistics();
}, [fetchStatistics]);

    const handlePresetClick = (preset) => {
      const now = new Date();
      const end = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));
      let start = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
      
      switch (preset) {
        case 'today':
          // start and end are already set correctly
          break;
        case 'yesterday':
          end.setUTCDate(end.getUTCDate() - 1);
          start.setUTCDate(start.getUTCDate() - 1);
          break;
        case 'lastWeek':
          start.setUTCDate(start.getUTCDate() - 7);
          break;
        case 'lastMonth':
          start.setUTCMonth(start.getUTCMonth() - 1);
          break;
        default:
          start.setUTCMonth(start.getUTCMonth() - 1);
      }
      setStartDate(start);
      setEndDate(end);
      setActivePreset(preset);
    };
  
    const orderTrendData = useMemo(() => {
        if (!statistics || !statistics.orders_trend_over_time) return [];
        return Object.entries(statistics.orders_trend_over_time)
          .map(([dateString, count]) => ({
            date: new Date(dateString),
            count
          }))
          .sort((a, b) => a.date - b.date);
      }, [statistics]);
    
      const statusData = useMemo(() => {
        if (!statistics || !statistics.status_counts) return [];
        return Object.entries(statistics.status_counts).map(([status, count]) => ({
          name: status,
          value: count
        }));
      }, [statistics]);
  
      const formatXAxis = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      };

    return (
    <PageContainer>
      <Card>
        <Title>Notary Order Statistics</Title>
        <DateRangeContainer>
          <DatePickerWrapper>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(new Date(date.setUTCHours(0,0,0,0)))}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
            />
          </DatePickerWrapper>
          <DateRangeSeparator>to</DateRangeSeparator>
          <DatePickerWrapper>
            <DatePicker
              selected={endDate}
              onChange={date => setEndDate(new Date(date.setUTCHours(23,59,59,999)))}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="yyyy-MM-dd"
            />
          </DatePickerWrapper>
        </DateRangeContainer>
        <div>
          <PresetButton active={activePreset === 'today'} onClick={() => handlePresetClick('today')}>Today</PresetButton>
          <PresetButton active={activePreset === 'yesterday'} onClick={() => handlePresetClick('yesterday')}>Yesterday</PresetButton>
          <PresetButton active={activePreset === 'lastWeek'} onClick={() => handlePresetClick('lastWeek')}>Last 7 days</PresetButton>
          <PresetButton active={activePreset === 'lastMonth'} onClick={() => handlePresetClick('lastMonth')}>Last 30 days</PresetButton>
        </div>
      </Card>

      <TableContainer>
          {loading && (
            <LoadingOverlay>
              <CircularProgress />
            </LoadingOverlay>
          )}
      {statistics && (
        <>
          <Row className="mb-4">
            <Col md={3}>
              <StatCard>
                <StatValue>{statistics.total_orders || 0}</StatValue>
                <StatLabel>Total Orders</StatLabel>
              </StatCard>
            </Col>
            <Col md={3}>
              <StatCard>
                <StatValue>
                  {statistics.average_time_to_in_progress != null 
                    ? `${statistics.average_time_to_in_progress.toFixed(2)} hours` 
                    : '0.00 hours'}
                </StatValue>
                <StatLabel>Avg. Time to In Progress</StatLabel>
              </StatCard>
            </Col>
            <Col md={3}>
              <StatCard>
                <StatValue>
                  {statistics.average_time_to_completion != null 
                    ? `${statistics.average_time_to_completion.toFixed(2)} hours` 
                    : '0.00 hours'}
                </StatValue>
                <StatLabel>Avg. Time to Completion</StatLabel>
              </StatCard>
            </Col>
            <Col md={3}>
              <StatCard>
                <StatValue>{orderTrendData[orderTrendData.length - 1]?.count || 0}</StatValue>
                <StatLabel>Latest Day Order Count</StatLabel>
              </StatCard>
            </Col>
          </Row>

          <Card>
            <Title>Order Trend by Day</Title>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={orderTrendData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date" 
                  tickFormatter={formatXAxis}
                  type="category"
                />
                <YAxis />
                <Tooltip 
                  labelFormatter={(label) => new Date(label).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#1a73e8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </Card>

          <Card>
          <Title>Orders by Service</Title>
          <StyledTable>
            <thead>
              <tr>
                <th>Service</th>
                <th>Total Count</th>
                {Object.values(NotaryOrderStatus).map(status => (
                  <th key={status}>{status}</th>
                ))}
                <th>Total Cost (Finished Orders)</th>
              </tr>
            </thead>
            <tbody>
              {sortedServiceData.map((item, index) => (
                <tr key={index}>
                  <td>{item.service}</td>
                  <td>{item.total_count}</td>
                  {Object.values(NotaryOrderStatus).map(status => (
                    <td key={status}>{item.status_counts[status] || 0}</td>
                  ))}
                  <td>${item.total_cost.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </Card>

        <Row>
            <Col md={6}>
                <Card>
                    <Title>Orders by Status</Title>
                    <ModernPieChart data={statusData} />
                </Card>
            </Col>
            <Col md={6}>
            <Card>
                        <Title>Orders and Revenue by Service</Title>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={serviceOrdersAndCostData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="service" />
                                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                                <Tooltip />
                                <Legend />
                                <Bar yAxisId="left" dataKey="totalOrders" fill="#8884d8" name="Total Orders" />
                                <Bar yAxisId="right" dataKey="totalCost" fill="#82ca9d" name="Total Revenue ($)" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Card>
            </Col>
        </Row>
        </>
      )}
      </TableContainer>
    </PageContainer>
  );
};

export default NotaryStatistics;
import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend, Label } from 'recharts';
import styled from 'styled-components';

const ChartContainer = styled.div`
  height: 400px;
  width: 100%;
  position: relative;
`;

const LegendContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  width: 45%;
`;

const ColorBox = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 2px;
`;

const LegendText = styled.span`
  font-size: 14px;
  color: #5f6368;
`;

const COLORS = ['#4285F4', '#34A853', '#FBBC05', '#EA4335', '#FF6D01', '#46BDC6'];

const ModernPieChart = ({ data }) => {
  const totalValue = data.reduce((sum, item) => sum + item.value, 0);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const item = payload[0].payload;
      return (
        <div style={{ background: 'white', padding: '10px', border: '1px solid #ccc', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
          <p style={{ margin: 0, fontWeight: 'bold' }}>{item.name}</p>
          <p style={{ margin: 0 }}>{`Orders: ${item.value}`}</p>
          <p style={{ margin: 0 }}>{`Percentage: ${((item.value / totalValue) * 100).toFixed(2)}%`}</p>
        </div>
      );
    }
    return null;
  };

  const CustomLegend = ({ payload }) => (
    <LegendContainer>
      {payload.map((entry, index) => (
        <LegendItem key={`legend-${index}`}>
          <ColorBox style={{ backgroundColor: entry.color }} />
          <LegendText>
            <strong>{entry.value}</strong>: {entry.payload.value} 
            <br />
            {((entry.payload.value / totalValue) * 100).toFixed(2)}%
          </LegendText>
        </LegendItem>
      ))}
    </LegendContainer>
  );

  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius="60%"
            outerRadius="80%"
            fill="#8884d8"
            paddingAngle={2}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
            <Label
              content={({ viewBox }) => {
                const { cx, cy } = viewBox;
                return (
                  <g>
                    <text x={cx} y={cy - 10} textAnchor="middle" dominantBaseline="central" style={{ fontSize: '24px', fontWeight: 'bold', fill: '#202124' }}>
                      {totalValue}
                    </text>
                    <text x={cx} y={cy + 20} textAnchor="middle" dominantBaseline="central" style={{ fontSize: '14px', fill: '#5f6368' }}>
                      Total Orders
                    </text>
                  </g>
                );
              }}
              position="center"
            />
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend content={<CustomLegend />} verticalAlign="bottom" height={100} />
        </PieChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default ModernPieChart;
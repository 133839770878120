import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container, Button, Form, Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { FaFilter } from 'react-icons/fa';
import { fetchSites, updateSite, toggleSiteActive, createSite } from './apiSites';
import { SiteSlidingPanel } from '../customViews/GoogleStyleSlidingPanel';
import { AddSiteModal, EditSiteModal, ToggleSiteActiveModal } from '../customViews/modals';

const PageContainer = styled(Container)`
  width: 100%;
  max-width: none;
  padding: 24px;
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
`;

const StyledDataTable = styled(DataTable)`
  .rdt_Table {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  .rdt_TableHead {
    font-weight: 500;
    color: #5f6368;
    background-color: #f1f3f4;
  }

  .rdt_TableCol {
    padding: 16px;
  }

  .rdt_TableCell {
    padding: 16px;
    font-size: 14px;
  }

  .rdt_Pagination {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;
  }
`;

const FilterDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    font-weight: 500;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
      box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    }
  }

  .dropdown-menu {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
    border: none;
    border-radius: 4px;
  }
`;

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;  // Add vertical scroll
  padding: 0 16px;  // Add some padding
`;

const GoogleButton = styled(Button)`
  background-color: #fff;
  border-color: #dadce0;
  color: #3c4043;
  font-weight: 500;
  text-transform: none;
  letter-spacing: normal;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #3c4043;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }
`;

const SlidingPanelOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const SlidingPanelContainer = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-400px'};
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 1001;
  overflow-y: auto;
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
`;

const CircularProgress = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const SiteManagement = () => {
    const [sites, setSites] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showToggleActiveModal, setShowToggleActiveModal] = useState(false);
    const [selectedSite, setSelectedSite] = useState(null);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [filters, setFilters] = useState({
      name: '',
      url: '',
      rss: '',
      weight: '',
      imageHost: '',
      logo: '',
    });
  
    const fetchSitesData = useCallback(async () => {
      setLoading(true);
      try {
        const params = {
          page: page.toString(),
          size: perPage.toString(),
          ...filters,
          weight: filters.weight || undefined,
        };
  
        const data = await fetchSites(params);
        setSites(data.content);
        setTotalRows(data.total_elements);
      } catch (error) {
        console.error('Error fetching sites:', error);
      } finally {
        setLoading(false);
      }
    }, [page, perPage, filters]);

    const closeSlidingPanel = () => {
      setIsPanelOpen(false);
    };

    const handleAddSite = async (newSite) => {
        try {
          await createSite(newSite);
          fetchSitesData();
        } catch (error) {
          console.error('Error adding site:', error);
        }
      };
  
    useEffect(() => {
      fetchSitesData();
    }, [fetchSitesData]);
  
    const handleFilterChange = (filterName, value) => {
      setFilters(prevFilters => ({
        ...prevFilters,
        [filterName]: value
      }));
    };
  
    const clearFilters = () => {
      setFilters({
        name: '',
        url: '',
        rss: '',
        weight: '',
        imageHost: '',
        logo: '',
      });
      setPage(0);
    };
  
    const handlePageChange = (page) => {
      setPage(page - 1);
    };
  
    const handlePerRowsChange = async (newPerPage, newPage) => {
      setPerPage(newPerPage);
    };
  
    const handleRowClick = (row) => {
      setSelectedSite(row);
      setIsPanelOpen(true);
    };
  
    const handleUpdateSite = async (updatedSite) => {
      try {
        await updateSite(updatedSite.id, updatedSite);
        fetchSitesData();
      } catch (error) {
        console.error('Error updating site:', error);
      }
    };
  
    const handleToggleActive = async (siteId) => {
      try {
        await toggleSiteActive(siteId);
        fetchSitesData();
      } catch (error) {
        console.error('Error toggling site active status:', error);
      }
    };
  
    const columns = useMemo(() => [
      {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
      },
      {
        name: 'Active',
        selector: row => row.active,
        sortable: true,
        cell: row => (
          <span>{row.active ? 'Yes' : 'No'}</span>
        ),
      },
      {
        name: 'URL',
        selector: row => row.url,
        sortable: true,
      },
      {
        name: 'RSS',
        selector: row => row.rss,
        sortable: true,
      },
      {
        name: 'Weight',
        selector: row => row.weight,
        sortable: true,
      },
      {
        name: 'Image Host',
        selector: row => row.image_host,
        sortable: true,
      },
      {
        name: 'Logo',
        selector: row => row.logo,
        sortable: true,
      },
    ], []);
  
    return (
      <PageContainer>
        <div className="d-flex justify-content-end gap-3 mb-4">
        <GoogleButton onClick={() => setShowAddModal(true)}>
          New Site
        </GoogleButton>
          <FilterDropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-filters">
              <FaFilter /> Filters
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-3" style={{ width: '300px' }}>
              <Form>
                {Object.keys(filters).map((key) => (
                  <Form.Group key={key} className="mb-2">
                    <Form.Label>{key.charAt(0).toUpperCase() + key.slice(1)}</Form.Label>
                    <Form.Control
                      type={key === 'weight' ? 'number' : 'text'}
                      value={filters[key]}
                      onChange={(e) => handleFilterChange(key, e.target.value)}
                    />
                  </Form.Group>
                ))}
                <div className="d-flex justify-content-end">
                  <GoogleButton onClick={clearFilters}>Clear Filters</GoogleButton>
                </div>
              </Form>
            </Dropdown.Menu>
          </FilterDropdown>
        </div>
  
        <TableContainer>
        <ScrollableContent>
            {loading && (
              <LoadingOverlay>
                <CircularProgress />
              </LoadingOverlay>
            )}
          <StyledDataTable
            columns={columns}
            data={sites}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            paginationDefaultPage={page + 1}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            progressPending={loading}
            progressComponent={<div />}
            onRowClicked={handleRowClick}
            pointerOnHover
            highlightOnHover
          />
          </ScrollableContent>
        </TableContainer>

        <EditSiteModal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          site={selectedSite || {}}
          handleUpdateSite={handleUpdateSite}
        />

    <AddSiteModal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        handleAddSite={handleAddSite}
      />

    <ToggleSiteActiveModal
        show={showToggleActiveModal}
        onHide={() => setShowToggleActiveModal(false)}
        site={selectedSite || {}}
        handleToggleActive={handleToggleActive}
      />

      {isPanelOpen && (
        <SlidingPanelOverlay onClick={closeSlidingPanel} />
      )}
      
      <SlidingPanelContainer isOpen={isPanelOpen}>
        <SiteSlidingPanel
          selectedSite={selectedSite}
          openEditModal={() => setShowEditModal(true)}
          openToggleActiveModal={() => setShowToggleActiveModal(true)}
          onClose={closeSlidingPanel}
        />
      </SlidingPanelContainer>
    </PageContainer>
  );
};

export default SiteManagement;
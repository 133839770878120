import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Form, Button, Card } from 'react-bootstrap';
import { FaPlus, FaTrash, FaEdit } from 'react-icons/fa';
import { 
  createIncidentPeople, 
  updateIncidentPeopleType, 
  fetchProvinces, 
  fetchMunicipalities, 
  updateIncidentPeople, 
  fetchIncidentActivities, 
  createIncidentActivity, 
  updateIncidentActivity,
  deleteIncidentActivity  } from './apiIncidentPeople';

const GoogleModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.3), 0 4px 8px 3px rgba(60,64,67,0.15);
  }

  .modal-header {
    border-bottom: 1px solid #e8eaed;
    padding: 24px 24px 20px;
  }

  .modal-title {
    font-size: 22px;
    color: #202124;
    font-weight: 400;
  }

  .modal-body {
    padding: 24px;
  }

  .modal-footer {
    border-top: 1px solid #e8eaed;
    padding: 20px 24px 24px;
  }
`;

const GoogleForm = styled(Form)`
  .form-label {
    font-weight: 500;
    color: #5f6368;
    margin-bottom: 8px;
  }

  .form-control {
    border-radius: 4px;
    border: 1px solid #dadce0;
    padding: 8px 12px;
    font-size: 14px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
    }
  }
`;

const GoogleButton = styled(Button)`
  background-color: #1a73e8;
  border-color: #1a73e8;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 8px 24px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #1765cc;
    border-color: #1765cc;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }
`;

const ImagePreview = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const ImageInputGroup = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
  margin-top: 16px;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
`;

const DeleteButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px 8px;
  background-color: rgba(255, 0, 0, 0.8);
  border: none;
  border-radius: 4px;
`;

const PreviewImage = styled.div`
  position: relative;
  height: 80px;
`;

const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 1200px;
    margin: 1.75rem auto;
  }

  .modal-content {
    min-height: 600px;
  }

  .modal-body {
    display: flex;
    gap: 24px;
    padding: 24px;
  }
`;

const FormContainer = styled.div`
  flex: 0 0 400px;
`;

const ListContainer = styled.div`
  flex: 1;
  position: relative;
  min-height: 400px;
  border-left: 1px solid #e8eaed;
  padding-left: 24px;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const LoadingSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #1a73e8;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ActivityList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
`;

const ActivityCard = styled(Card)`
  border: none;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3);
  
  &:hover {
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.3), 0 4px 8px 3px rgba(60,64,67,0.15);
  }
`;

const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 8px;
  margin-top: 12px;
`;

const ImageThumbnail = styled.img`
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
`;


export const CreateEditModal = ({ show, onHide, person, onSuccess, types }) => {
    const [formData, setFormData] = useState({
        type: '',
        name: '',
        province: '',
        municipality: '',
        date: '',
        profile_image: '',
        images: [],
      });
      const [provinces, setProvinces] = useState([]);
      const [municipalities, setMunicipalities] = useState([]);
      const [newImageUrl, setNewImageUrl] = useState('');
      const [validated, setValidated] = useState(false);
    
      useEffect(() => {
        if (person) {
          setFormData({
            type: person.type.code,
            name: person.name,
            profile_image: person.name.profile_image,
            province: person.province,
            municipality: person.municipality,
            date: new Date(person.date).toISOString().split('T')[0],
            profile_image: person.profile_image || '',
            images: person.images || [],
          });
        } else {
          // Reset form when creating new
          setFormData({
            type: '',
            name: '',
            province: '',
            municipality: '',
            date: '',
            profile_image: '',
            images: [],
          });
        }
        loadProvinces();
      }, [person, show]);

  const loadProvinces = async () => {
    try {
      const data = await fetchProvinces();
      setProvinces(data);
    } catch (error) {
      console.error('Error loading provinces:', error);
    }
  };

  useEffect(() => {
    const loadMunicipalities = async () => {
      if (formData.province) {
        try {
          const data = await fetchMunicipalities();
          // Filtrar municipios por provincia seleccionada
          const filteredMunicipalities = data.filter(
            mun => mun.province === formData.province
          );
          setMunicipalities(filteredMunicipalities);
        } catch (error) {
          console.error('Error loading municipalities:', error);
        }
      } else {
        setMunicipalities([]);
      }
    };

    loadMunicipalities();
  }, [formData.province]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    try {
      const data = {
        ...formData,
        date: new Date(formData.date).toISOString()
      };

      if (person) {
        await updateIncidentPeople(person.id, data);
      } else {
        await createIncidentPeople(data);
      }
      onSuccess();
      onHide();
    } catch (error) {
      console.error('Error saving incident people:', error);
    }
  };

  const handleAddImage = (e) => {
    e.preventDefault(); // Prevent form submission
    if (newImageUrl.trim()) {
      setFormData(prev => ({
        ...prev,
        images: [...prev.images, newImageUrl.trim()]
      }));
      setNewImageUrl('');
    }
  };

  const handleRemoveImage = (index) => {
    setFormData(prev => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index)
    }));
  };

  return (
    <GoogleModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{person ? 'Edit Person' : 'Add New Person'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <Form.Select
              required
              value={formData.type}
              onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value }))}
            >
              <option value="">Select type</option>
              {types.map((type) => (
                <option key={type.code} value={type.code}>
                  {type.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              required
              type="text"
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Profile Image URL</Form.Label>
            <Form.Control
              type="url"
              value={formData.profile_image}
              onChange={(e) => setFormData(prev => ({ ...prev, profile_image: e.target.value }))}
              placeholder="Enter profile image URL"
            />
            {formData.profile_image && (
              <ImagePreview>
                <PreviewImage src={formData.profile_image} alt="Profile" />
              </ImagePreview>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Province</Form.Label>
            <Form.Select
              required
              value={formData.province}
              onChange={(e) => setFormData(prev => ({ 
                ...prev, 
                province: e.target.value,
                municipality: '' // Reset municipality when province changes
              }))}
            >
              <option value="">Select province</option>
              {provinces.map((province) => (
                <option key={province.province} value={province.province}>
                  {province.province}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Municipality</Form.Label>
            <Form.Select
              required
              value={formData.municipality}
              onChange={(e) => setFormData(prev => ({ ...prev, municipality: e.target.value }))}
              disabled={!formData.province}
            >
              <option value="">Select municipality</option>
              {municipalities.map((municipality) => (
                <option key={municipality.municipality} value={municipality.municipality}>
                  {municipality.municipality}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Date</Form.Label>
            <Form.Control
              required
              type="date"
              value={formData.date}
              onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Images</Form.Label>
            <Form>
              <ImageInputGroup>
                <Form.Control
                  type="url"
                  value={newImageUrl}
                  onChange={(e) => setNewImageUrl(e.target.value)}
                  placeholder="Enter image URL"
                />
                <Button 
                  variant="outline-primary" 
                  onClick={handleAddImage}
                  type="button"  // Prevent form submission
                >
                  <FaPlus />
                </Button>
              </ImageInputGroup>
            </Form>
            <ImageContainer>
              {formData.images.map((url, index) => (
                <ImageWrapper key={index}>
                  <Image src={url} alt={`Image ${index + 1}`} />
                  <DeleteButton
                    variant="danger"
                    size="sm"
                    onClick={() => handleRemoveImage(index)}
                    type="button"
                  >
                    <FaTrash />
                  </DeleteButton>
                </ImageWrapper>
              ))}
            </ImageContainer>
          </Form.Group>

          <div className="d-flex justify-content-end gap-2">
            <Button variant="secondary" onClick={onHide} type="button">
              Cancel
            </Button>
            <GoogleButton type="submit">
              {person ? 'Update' : 'Create'}
            </GoogleButton>
          </div>
        </GoogleForm>
      </Modal.Body>
    </GoogleModal>
  );
};

export const TypeModal = ({ show, onHide, person, onSuccess, types }) => {
  const [selectedType, setSelectedType] = useState('');

  useEffect(() => {
    if (person) {
      setSelectedType(person.type.code);
    }
  }, [person]);

  const handleSubmit = async () => {
    try {
      await updateIncidentPeopleType(person.id, selectedType);
      onSuccess();
      onHide();
    } catch (error) {
      console.error('Error updating type:', error);
    }
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Change Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group>
            <Form.Label>Current Type: {person?.type.name}</Form.Label>
            <Form.Select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <option value="">Select type</option>
              {types.map((type) => (
                <option 
                  key={type.code} 
                  value={type.code}
                  disabled={type.code === person?.type.code}
                >
                  {type.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <GoogleButton 
          onClick={handleSubmit}
          disabled={!selectedType || selectedType === person?.type.code}
        >
          Update Type
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const ProfileImageModal = ({ show, onHide, person, onSuccess }) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (person?.profile_image) {
      setImageUrl(person.profile_image);
    }
  }, [person]);

  const handleSubmit = async () => {
    try {
      await updateIncidentPeople(person.id, { 
        ...person,
        profile_image: imageUrl 
      });
      onSuccess();
      onHide();
    } catch (error) {
      console.error('Error updating profile image:', error);
    }
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Profile Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group>
            <Form.Label>Image URL</Form.Label>
            <Form.Control
              type="url"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              placeholder="Enter image URL"
            />
          </Form.Group>
          {imageUrl && (
            <ImagePreview>
              <PreviewImage src={imageUrl} alt="Profile preview" />
            </ImagePreview>
          )}
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <GoogleButton 
          onClick={handleSubmit}
          disabled={!imageUrl}
        >
          Update Profile Image
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const ImagesModal = ({ show, onHide, person, onSuccess }) => {
    const [images, setImages] = useState([]);
    const [newImageUrl, setNewImageUrl] = useState('');
  
    useEffect(() => {
      if (person?.images) {
        setImages(person.images);
      }
    }, [person]);
  
    const handleAddImage = () => {
      if (newImageUrl.trim()) {
        setImages(prev => [...prev, newImageUrl.trim()]);
        setNewImageUrl('');
      }
    };
  
    const handleRemoveImage = (index) => {
      setImages(prev => prev.filter((_, i) => i !== index));
    };
  
    const handleSubmit = async () => {
      try {
        await updateIncidentPeople(person.id, {
          ...person,
          images
        });
        onSuccess();
        onHide();
      } catch (error) {
        console.error('Error updating images:', error);
      }
    };
  
    return (
      <GoogleModal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Manage Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GoogleForm>
            <Form.Group className="mb-3">
              <Form.Label>Add New Image</Form.Label>
              <ImageInputGroup>
                <Form.Control
                  type="url"
                  value={newImageUrl}
                  onChange={(e) => setNewImageUrl(e.target.value)}
                  placeholder="Enter image URL"
                />
                <Button variant="outline-primary" onClick={handleAddImage}>
                  <FaPlus />
                </Button>
              </ImageInputGroup>
            </Form.Group>
  
            <Form.Group>
              <Form.Label>Current Images</Form.Label>
              <ImageContainer>
                {images.map((url, index) => (
                  <ImageWrapper key={index}>
                    <Image src={url} alt={`Image ${index + 1}`} />
                    <DeleteButton
                      variant="danger"
                      size="sm"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <FaTrash />
                    </DeleteButton>
                  </ImageWrapper>
                ))}
              </ImageContainer>
            </Form.Group>
          </GoogleForm>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <GoogleButton onClick={handleSubmit}>
            Update Images
          </GoogleButton>
        </Modal.Footer>
      </GoogleModal>
    );
  };



  export const IncidentActivitiesModal = ({ show, onHide, selectedPerson, onActivityCountUpdate }) => {
    const [activities, setActivities] = useState([]);
    const [formData, setFormData] = useState({
      description: '',
      date: new Date().toISOString().split('T')[0],
      images: [],
    });
    const [newImageUrl, setNewImageUrl] = useState('');
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      if (show && selectedPerson?.id) {
        loadActivities();
      }
    }, [show, selectedPerson]);
  
    const loadActivities = async () => {
      setLoading(true);
      try {
        const data = await fetchIncidentActivities(selectedPerson.id);
        setActivities(data.content);
        onActivityCountUpdate?.(data.content.length);
      } catch (error) {
        console.error('Error loading activities:', error);
      } finally {
        setLoading(false);
      }
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      
      if (form.checkValidity() === false) {
        event.stopPropagation();
        setValidated(true);
        return;
      }
  
      try {
        const data = {
          ...formData,
          parent_id: selectedPerson.id,
          date: new Date(formData.date).toISOString(),
        };
  
        let newActivity;
        if (selectedActivity) {
          newActivity = await updateIncidentActivity(selectedActivity.id, data);
          setActivities(prev => prev.map(act => 
            act.id === selectedActivity.id ? newActivity : act
          ));
        } else {
          newActivity = await createIncidentActivity(data);
          setActivities(prev => [newActivity, ...prev]);
        }
  
        resetForm();
        onActivityCountUpdate?.(activities.length + (selectedActivity ? 0 : 1));
      } catch (error) {
        console.error('Error saving activity:', error);
      }
    };
  
    const handleDelete = async (activityId) => {
      if (window.confirm('Are you sure you want to delete this activity?')) {
        try {
          await deleteIncidentActivity(activityId);
          setActivities(prev => prev.filter(act => act.id !== activityId));
          if (selectedActivity?.id === activityId) {
            resetForm();
          }
          onActivityCountUpdate?.(activities.length - 1);
        } catch (error) {
          console.error('Error deleting activity:', error);
        }
      }
    };
  
    const handleEdit = (activity) => {
      setSelectedActivity(activity);
      setFormData({
        description: activity.description,
        date: new Date(activity.date).toISOString().split('T')[0],
        images: activity.images || [],
      });
    };
  
    const resetForm = () => {
      setFormData({
        description: '',
        date: new Date().toISOString().split('T')[0],
        images: [],
      });
      setNewImageUrl('');
      setSelectedActivity(null);
      setValidated(false);
    };
  
    const handleAddImage = () => {
      if (newImageUrl.trim()) {
        setFormData(prev => ({
          ...prev,
          images: [...prev.images, newImageUrl.trim()]
        }));
        setNewImageUrl('');
      }
    };
  
    const handleRemoveImage = (index) => {
      setFormData(prev => ({
        ...prev,
        images: prev.images.filter((_, i) => i !== index)
      }));
    };
  
    return (
      <ModalContainer show={show} onHide={onHide} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Activities Log - {selectedPerson?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormContainer>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  value={formData.description}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    description: e.target.value 
                  }))}
                  disabled={loading}
                />
              </Form.Group>
  
              <Form.Group className="mb-3">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  required
                  value={formData.date}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    date: e.target.value 
                  }))}
                  disabled={loading}
                />
              </Form.Group>
  
              <Form.Group className="mb-3">
                <Form.Label>Images</Form.Label>
                <div className="d-flex gap-2">
                  <Form.Control
                    type="url"
                    value={newImageUrl}
                    onChange={(e) => setNewImageUrl(e.target.value)}
                    placeholder="Enter image URL"
                    disabled={loading}
                  />
                  <Button 
                    variant="outline-primary" 
                    onClick={handleAddImage}
                    disabled={loading}
                  >
                    <FaPlus />
                  </Button>
                </div>
  
                <ImagesGrid>
                  {formData.images.map((url, index) => (
                    <div key={index} className="position-relative">
                      <ImageThumbnail src={url} alt={`Preview ${index + 1}`} />
                      <Button 
                        variant="danger"
                        size="sm"
                        onClick={() => handleRemoveImage(index)}
                        style={{ 
                          position: 'absolute',
                          top: '4px',
                          right: '4px',
                          padding: '2px 4px'
                        }}
                        disabled={loading}
                      >
                        <FaTrash />
                      </Button>
                    </div>
                  ))}
                </ImagesGrid>
              </Form.Group>
  
              <div className="d-flex justify-content-end gap-2">
                {selectedActivity && (
                  <Button 
                    variant="secondary" 
                    onClick={resetForm}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                )}
                <Button 
                  variant="primary" 
                  type="submit"
                  disabled={loading}
                >
                  {selectedActivity ? 'Update Activity' : 'Add Activity'}
                </Button>
              </div>
            </Form>
          </FormContainer>
  
          <ListContainer>
            {loading && (
              <LoadingOverlay>
                <LoadingSpinner />
              </LoadingOverlay>
            )}
            <ActivityList>
              {activities.map((activity) => (
                <ActivityCard key={activity.id}>
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <small className="text-muted">
                        {new Date(activity.date).toLocaleDateString()}
                      </small>
                      <div className="d-flex gap-2">
                        <Button 
                          variant="outline-primary" 
                          size="sm"
                          onClick={() => handleEdit(activity)}
                        >
                          <FaEdit />
                        </Button>
                        <Button 
                          variant="outline-danger" 
                          size="sm"
                          onClick={() => handleDelete(activity.id)}
                        >
                          <FaTrash />
                        </Button>
                      </div>
                    </div>
                    <p className="mb-2">{activity.description}</p>
                    {activity.images?.length > 0 && (
                      <ImagesGrid>
                        {activity.images.map((image, index) => (
                          <ImageThumbnail
                            key={index}
                            src={image}
                            alt={`Activity ${index + 1}`}
                          />
                        ))}
                      </ImagesGrid>
                    )}
                  </Card.Body>
                </ActivityCard>
              ))}
            </ActivityList>
          </ListContainer>
        </Modal.Body>
      </ModalContainer>
    );
  };
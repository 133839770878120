import api from '../apiBase';

export const fetchSites = async (params) => {
  try {
    const response = await api.get('/site/', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching sites:', error);
    throw error;
  }
};

export const createSite = async (site) => {
  try {
    const response = await api.post('/site', site);
    return response.data;
  } catch (error) {
    console.error('Error creating site:', error);
    throw error;
  }
};

export const updateSite = async (id, site) => {
  try {
    const response = await api.put(`/site/${id}`, site);
    return response.data;
  } catch (error) {
    console.error('Error updating site:', error);
    throw error;
  }
};

export const deleteSite = async (id) => {
  try {
    const response = await api.delete(`/site/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting site:', error);
    throw error;
  }
};

export const toggleSiteActive = async (id) => {
    try {
      const response = await api.put(`/site/${id}/toggle-active`);
      return response.data;
    } catch (error) {
      console.error('Error toggling site active status:', error);
      throw error;
    }
  };

export const getSiteById = async (id) => {
  try {
    const response = await api.get(`/site/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching site by id:', error);
    throw error;
  }
};